import { SplitSeries } from '../../../../shared/charts/split-chart';
import {
  Series,
  SeriesMarkLine,
} from '../../../../shared/components/time-series-chart/time-series-chart.definitions';
import { ChartGroupDefinition } from '../../../../shared/charts/chart-groups/chart-groups.definitions';

export type SetupTimeSeriesChartProps = {
  startTimestamp?: Date;
  stopTimestamp?: Date;
  isShowLoading: boolean;
  seriesMarkLines: SeriesMarkLine[];
  series: Record<string, Series[]>;
  seriesData?: SplitSeries[];
  groups: ChartGroupDefinition;
};

export const EMPTY_GROUP_DEFAULT_Y_AXIS_MAX = 1;
export const EMPTY_GROUP_DEFAULT_Y_AXIS_MIN = 0;
