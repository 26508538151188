import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import {
  ProcessRecordAttributes,
  StructuredProcessRecordObject,
} from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import useClient from '../../../../shared/common/hooks/use-client';
import { PrsException } from '../../../../shared/common/types/generated/prs.generated';

import { UseEditProcessRecordAttributesInput } from './use-edit-process-record-attributes.definitions';

function useEditProcessRecordAttributes({
  processRecordId,
  onMutate,
  onSuccess,
  onError,
}: UseEditProcessRecordAttributesInput) {
  const queryClient = useQueryClient();
  const { prsClient } = useClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<
    ProcessRecordAttributes,
    PrsException,
    ProcessRecordAttributes,
    StructuredProcessRecordObject
  >({
    mutationKey: ['edit-process-records-attribute', processRecordId],
    mutationFn: async (attributes) => {
      // fetch
      prsClient.authorize(await acquireAccessToken());

      return prsClient.updateProcessRecordAttributesById(attributes, processRecordId);
    },
    onSuccess: (attributes) => {
      queryClient.invalidateQueries({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
      });

      queryClient.setQueryData<StructuredProcessRecordObject>(
        [QKey.PROCESS_RECORDS, processRecordId],
        (old) => old && { ...old, attributes },
      );

      queryClient.invalidateQueries({
        queryKey: [QKey.RECIPE_OPTIMIZATION, processRecordId],
      });

      onSuccess?.(attributes);
    },
    onMutate: async (attributes) => {
      // optimistic update

      // cancel any outgoing re-fetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QKey.PROCESS_RECORDS] });

      // snapshot the previous value
      const previousProcessRecord = queryClient.getQueryData<StructuredProcessRecordObject>([
        QKey.PROCESS_RECORDS,
        processRecordId,
      ]);

      queryClient.setQueryData<StructuredProcessRecordObject>(
        [QKey.PROCESS_RECORDS, processRecordId],
        (old) => old && { ...old, attributes },
      );

      onMutate?.(attributes);

      // return a context object with the snapshot value
      return previousProcessRecord;
    },
    // if the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newAttributes, previousProcessRecord) => {
      queryClient.setQueryData([QKey.PROCESS_RECORDS, processRecordId], previousProcessRecord);
      onError?.(err, newAttributes, previousProcessRecord);
    },
  });
}

export default useEditProcessRecordAttributes;
