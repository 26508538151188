import React, { useState } from 'react';
import { ActivityIndicator, Modal, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { DataTrackEmptyModalSuccess } from '../../../analytics/components/data-track-empty-modal';

import {
  DataTrackCreateModalProps,
  DataTrackCustomForm,
} from './data-track-create-modal.definitions';
import { ModalContent } from './modal-content';
import { filterDataTracks, getIsValid } from './data-track-create-modal.helpers';

export const newDataTrack: DataTrackCustomForm = {
  displayName: '',
  dataTrackType: '',
  engineeringUnit: '',
  isEdited: false,
};

function DataTrackCreateModal({
  trigger,
  defaultOpen = false,
  dataTrackTypes,
  isDisabled,
  isPending,
  isError,
  isSuccess,
  onSave,
  onOpenChange,
}: DataTrackCreateModalProps) {
  const [dataTracks, setDataTracks] = useState<DataTrackCustomForm[]>([newDataTrack]);
  const addDataTrack = () => {
    setDataTracks([...dataTracks, newDataTrack]);
  };

  const handleChange = (data: DataTrackCustomForm, index: number) => {
    const newDataTracks = [...dataTracks];
    newDataTracks[index] = { ...data, isEdited: true };

    setDataTracks(newDataTracks);
  };

  const localTypes = dataTracks.map((dt) => dt.dataTrackType);
  const isValid = getIsValid(dataTracks, dataTrackTypes, localTypes);

  const handleSaveDataTracks = () => {
    if (isValid) {
      const dataTracksFiltered = filterDataTracks(dataTracks);
      const customDataTracks = dataTracksFiltered.map((item) => {
        const track = { ...item, displayName: item.dataTrackType };
        delete track.isEdited;
        return track;
      });

      onSave(customDataTracks);
    }
  };

  const handleCancel = () => {
    setDataTracks([newDataTrack]);
  };

  const handleOpenChange = (isOpen: boolean) => {
    setDataTracks([newDataTrack]);
    onOpenChange?.(isOpen);
  };

  if (isSuccess) {
    return <DataTrackEmptyModalSuccess defaultOpen trigger={trigger} onOpenChange={onOpenChange} />;
  }

  return (
    <Modal
      defaultOpen={defaultOpen}
      trigger={trigger}
      onOpenChange={handleOpenChange}
      title={
        <FormattedMessage
          defaultMessage="Create Data Tracks"
          id="T936fJ"
          description="Create Data Tracks Title"
        />
      }
    >
      <Modal.Content>
        {isError && (
          <div className="mb-6">
            <NotificationMessage status="error">
              <FormattedMessage
                defaultMessage="An internal error occurred while saving the data tracks. Please wait a moment and try again."
                id="SW/unD"
                description="Create Data Tracks Error message"
              />
            </NotificationMessage>
          </div>
        )}
        <ModalContent
          dataTracks={dataTracks}
          takenTypes={dataTrackTypes}
          localTypes={localTypes}
          addDataTrack={addDataTrack}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancelDataTrackButton" onClick={handleCancel}>
            <FormattedMessage
              description="Create Data Tracks: label for cancel button"
              defaultMessage="Cancel"
              id="+U8CTC"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          disabled={!isValid || isDisabled}
          variant="highlight"
          onClick={handleSaveDataTracks}
          data-testid="createDataTrackButton"
          tailIcon={isPending && <ActivityIndicator size="sm" />}
        >
          <FormattedMessage
            description="Create Data Tracks: label for Create Data Tracks button"
            defaultMessage="Create"
            id="sxEuii"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackCreateModal;
