import React, { useState } from 'react';
import { Button, DeleteIcon, FailedIcon, List, PageLayout } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import useOrganizations from '../../../common/hooks/use-organizations/use-organizations';
import LoadingIndicator from '../../../../shared/components/loading-indicator';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import ConfirmDialog from '../../../components/confirm-dialog/confirm-dialog';
import useChangeStateOrganization from '../../../common/hooks/use-change-state-organization/use-change-state-organization';
import useDeleteOrganization from '../../../common/hooks/use-delete-organization/use-delete-organization';
import SearchInput from '../../../../shared/components/search-input';
import Footer from '../../../../shared/components/footer';

import { OrganizationsProps } from './organizations.definitions';
import seekOrganization from './organizations.helpers';

const styling = { b: (value: any) => <b>{value}</b> };
function Organizations({
  defaultSearch = '',
}: OrganizationsProps): React.FunctionComponentElement<OrganizationsProps> {
  const { data: organizations, isError, isLoading } = useOrganizations();
  const navigate = useNavigate();
  const { mutate: changeStateOrganization } = useChangeStateOrganization();
  const { mutate: deleteOrganization } = useDeleteOrganization();

  const { formatMessage, formatDate } = useIntl();
  const [searchParam, setSearchParam] = useState<string>(defaultSearch);
  const handleSearch = (val: string) => {
    const value = val.trim();
    setSearchParam(value);
  };
  const organizationItems =
    organizations?.map((i) => ({
      key: i.organizationId,
      title: i.organizationName,
      adminDisplayName: i.administratorDisplayName,
      adminEmail: i.administratorEmail,
      deactivatedAt: i.deactivatedAt,
      active: i.active,
    })) ?? [];

  const filteredOrganizationItems = organizationItems.filter((i) =>
    seekOrganization(searchParam, i.title, i.adminDisplayName, i.adminEmail),
  );

  return (
    <PageLayout sidebarOpen="none" className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar pageTitle="Organization Management" sidePanelOpen="none" />
        <PageLayout.Main.Content className="flex h-full flex-col gap-4 overflow-y-auto px-4 pb-4">
          <List data-testid="OrganizationList" className="rounded border">
            <List.Header sticky>
              <div className="flex min-w-[10rem] flex-row items-center justify-between gap-1 bg-white py-2">
                <div>
                  <Button
                    onClick={() => navigate(`${RouteDefinition.UserManagement}/organizations/new`)}
                    isSubmit
                  >
                    <FormattedMessage
                      description="Submit button label for register new organization."
                      defaultMessage="Register New Organization"
                      id="/tylJy"
                    />
                  </Button>
                </div>
                <SearchInput
                  placeholder={formatMessage({
                    defaultMessage: 'Type to search...',
                    description: 'User list searchbar placeholder',
                    id: 'ryfjtM',
                  })}
                  onDebouncedChange={handleSearch}
                  initialValue={searchParam}
                  expand="auto"
                  disabled={!organizations}
                  data-testid="process-record-list-search"
                />
              </div>
            </List.Header>

            <List.Items data-testid="organizations">
              {filteredOrganizationItems.map((item) => (
                <List.Item
                  key={item.key}
                  clickable
                  select="single"
                  lining="zebra"
                  onCheckedChange={() => {}}
                  onClick={() => {
                    navigate(`${RouteDefinition.UserManagement}/organizations/${item.key}`);
                  }}
                  actions={
                    item.active ? (
                      <List.Item.ActionArea stopPropagation>
                        <ConfirmDialog
                          defaultMessage={formatMessage({
                            defaultMessage: 'Deactivate',
                            id: 'eRIGlO',
                            description: 'Deactivate title.',
                          })}
                          title={formatMessage({
                            defaultMessage: 'Confirm Deactivation',
                            id: 'kyB+UR',
                            description: 'Confirm Deactivation title.',
                          })}
                          subtitle={formatMessage(
                            {
                              defaultMessage:
                                'The organization {title} will be deactivated. Members will no longer have access to the organization or the data stored. This <b>does not</b> remove the data associated with this organization.',
                              description: 'Deactivation message',
                              id: 'tbitBZ',
                            },
                            { title: item.title, ...styling },
                          )}
                          onConfirm={() => {
                            changeStateOrganization({ organizationId: item.key, active: false });
                          }}
                          trigger={
                            <Button
                              mood="neutral"
                              kind="ghost"
                              isSubmit
                              data-testid="OrganizationItem-deactivateButton"
                              leftIcon={<FailedIcon />}
                            />
                          }
                        />
                      </List.Item.ActionArea>
                    ) : (
                      <List.Item.ActionArea stopPropagation>
                        <ConfirmDialog
                          defaultMessage={formatMessage({
                            defaultMessage: 'Delete',
                            id: 'XE57Xw',
                            description: 'Delete title.',
                          })}
                          title={formatMessage({
                            defaultMessage: 'Confirm Deletion',
                            id: 'FuSwjG',
                            description: 'Confirm Deletion title.',
                          })}
                          subtitle={formatMessage(
                            {
                              defaultMessage:
                                'The organization {title} will be permanently deleted. This removes all data associated with this organization. <b>This cannot be undone.</b> Are you sure you want to delete the organization {title}?',
                              description: 'Deletion message',
                              id: '/DMtlH',
                            },
                            { title: item.title, ...styling },
                          )}
                          onConfirm={() => {
                            deleteOrganization(item.key);
                          }}
                          trigger={
                            <Button
                              mood="neutral"
                              kind="ghost"
                              isSubmit
                              data-testid="OrganizationItem-deleteButton"
                              leftIcon={<DeleteIcon />}
                            />
                          }
                        />
                      </List.Item.ActionArea>
                    )
                  }
                >
                  <List.Item.Content>
                    <div className="gap-4 text-sm max-md:flex max-md:flex-col md:grid md:grid-cols-3 md:grid-rows-1">
                      <List.Item.Description>
                        <List.Item.Title>
                          <div className="break-words text-gray-900">{item.title}</div>
                        </List.Item.Title>
                        {item?.deactivatedAt !== undefined && item?.active === false && (
                          <FormattedMessage
                            description="Organization: Organization is deactivated"
                            defaultMessage="Deactivated on {deactivatedAt}"
                            values={{
                              deactivatedAt: `${formatDate(item?.deactivatedAt, {
                                month: 'short',
                              })} ${new Date(item?.deactivatedAt).getDate()}, ${new Date(
                                item?.deactivatedAt,
                              ).getFullYear()}`,
                            }}
                            id="NfL0Sv"
                          />
                        )}
                      </List.Item.Description>
                      <div className="break-words text-gray-900" />
                      <div className="break-words text-gray-900">{item.adminEmail}</div>
                    </div>
                  </List.Item.Content>
                </List.Item>
              ))}
            </List.Items>

            {organizationItems && !organizationItems.length && (
              <span className="p-4">
                <FormattedMessage
                  description="Organization list: No matching Organization message"
                  defaultMessage="No matching organization."
                  id="i5YPJ0"
                />
              </span>
            )}

            {isError && (
              <span className="p-4 text-red">
                <FormattedMessage
                  description="Organization list view: error message when error occurred during loading."
                  defaultMessage="An error occurred while loading the list of organizations."
                  id="HPYwuE"
                />
              </span>
            )}

            {isLoading && (
              <span className="p-4">
                <LoadingIndicator />
              </span>
            )}

            <Outlet />
          </List>
          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
    </PageLayout>
  );
}

export default Organizations;
