import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Modal, NotificationMessage } from '@biss/react-horizon-web';

import {
  useFormValidation,
  useOrganization,
  useUpdateOrganization,
  useSubscriptions,
  useUpdateSubscriptions,
  useChangeStateOrganization,
} from '../../../common/hooks';
import { Organization } from '../../../common/types';
import LoadingIndicator from '../../../../shared/components/loading-indicator';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';

import InlineMessage from './inline-message/inline-message';
import { OrganizationDetailsState } from './organization-details.definitions';
import DataHowLabConfiguration from './datahowlab-configuration/datahowlab-configuration';
import ModalActions from './modal-actions';

function OrganizationDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  if (id === undefined) {
    throw new Error('An id has to be provided via the route.');
  }
  const [open, setOpen] = React.useState(true);
  const { data: organization, isLoading, error } = useOrganization(id);

  const [organizationDetailState, setOrganizationDetailState] = useState<OrganizationDetailsState>({
    saveEnabled: false,
    errors: new Map(),
    validity: new Map([['organizationName', true]]),
    isDataHowLabConnectionChanged: false,
  });

  const { mutate: changeStateOrganization } = useChangeStateOrganization();
  const { updateErrorsFromFocusEvent, updateErrorsAndValidityFromChangeEvent } =
    useFormValidation();
  const callbackRef = React.useRef<() => void>();

  const registerOnSave = (onSaveCallback: () => void) => {
    callbackRef.current = onSaveCallback;
  };
  const onClose = () => {
    navigate(`${RouteDefinition.UserManagement}/organizations`);
  };
  const { data: subscriptions } = useSubscriptions(id);
  const { mutate: updateSubscriptions, error: updateSubscriptionsError } = useUpdateSubscriptions(
    id,
    (successful) => {
      if (successful) {
        if (organizationDetailState.isDataHowLabSubscribed && callbackRef.current) {
          callbackRef.current();
        } else {
          setOpen(false);
          onClose();
        }
      }
    },
  );
  const { mutate: updateOrganization, error: updateOrganizationError } = useUpdateOrganization();
  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      const dhl = subscriptions.find((x) => x.packageKey === 'DataHowLab');
      setOrganizationDetailState((prevState) => ({
        ...prevState,
        isDataHowLabSubscribed: dhl?.isSubscribed ?? false,
      }));
    }
  }, [subscriptions]);

  const onSave = () => {
    if (subscriptions) {
      const dhl = subscriptions.find((x) => x.packageKey === 'DataHowLab');
      if (dhl) {
        dhl.isSubscribed = organizationDetailState.isDataHowLabSubscribed ?? false;
        updateSubscriptions(subscriptions);
      }
    }
    if (
      organization &&
      organizationDetailState.organization &&
      organization?.organizationName !== organizationDetailState.organization.organizationName
    ) {
      updateOrganization(organizationDetailState.organization);
    }
  };

  const onDataHowLabConfigurationVerified = (successful: boolean) => {
    setOrganizationDetailState((prevState) => ({
      ...prevState,
      isDataHowLabConnectionValid: successful,
    }));
  };

  const onDataHowLabConfigurationSaved = (successful: boolean) => {
    onDataHowLabConfigurationVerified(successful);
    if (successful) {
      setOpen(false);
      onClose();
    }
  };

  const onDataHowLabConfigurationChanged = (allInputsAreValid: boolean) => {
    setOrganizationDetailState((prevState) => ({
      ...prevState,
      isDataHowLabConnectionValid: undefined,
      saveEnabled: allInputsAreValid,
      isDataHowLabConnectionChanged: true,
    }));
  };

  const onDataHowLabSubscriptionChanged = (isSubscribed: boolean) => {
    setOrganizationDetailState((prevState) => ({
      ...prevState,
      isDataHowLabSubscribed: isSubscribed,
    }));
  };

  const areFormInputsInvalid = (validity: Map<string, boolean>): boolean | undefined =>
    Array.from(validity.values()).some((value) => value === false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrganizationDetailState((values) => {
      if (!values?.organization) {
        return values;
      }
      const { validity, errors } = updateErrorsAndValidityFromChangeEvent(values, event);
      return {
        ...values,
        organization: {
          ...values.organization,
          [name]: value,
        },
        validity,
        errors,
        saveEnabled: areFormInputsInvalid(validity) === false,
      };
    });
  };

  const validateFormat = (e: FocusEvent<HTMLInputElement>) => {
    setOrganizationDetailState((values) => ({
      ...values,
      errors: updateErrorsFromFocusEvent(values, e),
    }));
  };

  // view the organization in detail view when the data is loaded
  useEffect(() => {
    if (!organization) {
      return;
    }
    const organizationCopy = JSON.parse(JSON.stringify(organization)) as Organization;
    setOrganizationDetailState((prevState) => ({
      ...prevState,
      organization: organizationCopy,
    }));
  }, [
    organization?.organizationName,
    organization?.administratorDisplayName,
    organization?.active,
  ]);

  if (error) {
    return (
      <FormattedMessage
        description="An error occurred."
        defaultMessage="An error occurred. Message: {message}"
        values={{
          message: error.message,
        }}
        id="0o7n3J"
      />
    );
  }

  return (
    <Modal
      open={open}
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          onClose();
        }
      }}
      title={formatMessage({
        defaultMessage: 'Edit Organization',
        id: '6H8SpL',
        description: 'Edit organization title.',
      })}
    >
      {organizationDetailState.organization?.active === false && (
        <div className="p-2">
          <NotificationMessage
            variant="highlighted"
            status="warning"
            title="This organization is deactivated"
          />
        </div>
      )}
      <Modal.Content>
        {isLoading || organizationDetailState.organization === undefined ? (
          <LoadingIndicator />
        ) : (
          <div className="mb-4 flex flex-row flex-wrap gap-x-4">
            <div className="flex-1">
              <Input
                className="mb-4 w-full"
                id="organizationNameInput"
                name="organizationName"
                label={formatMessage({
                  defaultMessage: 'Organization name',
                  id: 'vANUKX',
                  description: 'Label for organizationNameInput input.',
                })}
                placeholder={formatMessage({
                  defaultMessage: 'Organization name',
                  id: 'S8i4xZ',
                  description: 'Placeholder for organizationNameInput input.',
                })}
                type="text"
                value={organizationDetailState.organization.organizationName}
                onChange={handleChange}
                error={organizationDetailState.errors.get('organizationName')}
                onBlur={validateFormat}
                pattern='^[^~^*\(\)+=\[\]"<>?\/\\]{0,99}$'
                required
              />
              <Input
                className="mb-4 w-full"
                id="organizationIdInput"
                name="organizationId"
                label={formatMessage({
                  defaultMessage: 'Organization ID',
                  id: 'PLIPNJ',
                  description: 'Label for organizationIdInput input.',
                })}
                placeholder={formatMessage({
                  defaultMessage: 'Organization ID',
                  id: 'pc78Sd',
                  description: 'Placeholder for organizationIdInput input.',
                })}
                type="text"
                value={organizationDetailState.organization.organizationId}
                disabled
              />
              <Input
                className="mb-4 w-full"
                id="administratorDislayNameInput"
                name="administratorDisplayName"
                label={formatMessage({
                  defaultMessage: 'Administrator name',
                  id: 'XMoUxe',
                  description: 'Label for administratorDislayNameInput input.',
                })}
                placeholder={formatMessage({
                  defaultMessage: 'Administrator name',
                  id: '2yR6tW',
                  description: 'Placeholder for administratorDislayNameInput input.',
                })}
                type="text"
                value={organizationDetailState.organization.administratorDisplayName}
                readOnly
                disabled={!organizationDetailState.organization?.active}
              />
              <Input
                className="w-full"
                id="administratorEmailInput"
                name="administratorEmail"
                label={formatMessage({
                  defaultMessage: 'Administrator e-mail address',
                  id: '3VZWoZ',
                  description: 'Label for administratorEmailInput input.',
                })}
                placeholder={formatMessage({
                  defaultMessage: 'Administrator e-mail address',
                  id: 'RTn8PY',
                  description: 'Placeholder for administratorEmailInput input.',
                })}
                type="email"
                value={organizationDetailState.organization.administratorEmail}
                readOnly
                disabled={!organizationDetailState.organization?.active}
              />
            </div>
            <DataHowLabConfiguration
              organizationId={id}
              dataHowLabSubscribed={organizationDetailState.isDataHowLabSubscribed ?? false}
              isOrganizationActive={organizationDetailState.organization.active}
              onVerified={onDataHowLabConfigurationVerified}
              onSaved={onDataHowLabConfigurationSaved}
              registerOnSave={registerOnSave}
              onConfigurationChanged={onDataHowLabConfigurationChanged}
              onSubscriptionChanged={onDataHowLabSubscriptionChanged}
            />
          </div>
        )}
        <InlineMessage
          isDataHowLabConnectionValid={organizationDetailState?.isDataHowLabConnectionValid}
          error={updateSubscriptionsError ?? updateOrganizationError}
        />
      </Modal.Content>
      <ModalActions
        organizationDetailState={organizationDetailState}
        onActivateOrganization={() => {
          if (organizationDetailState.organization !== undefined) {
            changeStateOrganization({
              organizationId: organizationDetailState.organization?.organizationId,
              active: true,
            });
          }
        }}
        onSave={onSave}
      />
    </Modal>
  );
}

export default OrganizationDetails;
