import { REFERENCE_CURVE_POSTFIX } from '../../../monitoring/scenes/setup-details/setup-details-content/setup-details-content.helpers';
import { SeriesId } from '../../components/time-series-chart';
import { DataSet } from '../chart-dataset';
import { GroupName } from '../chart-groups';
import { sortLegendForActualAndReferenceTogether } from '../chart-legend';
import { EChartSeriesConfig, getMarkLineSeries, MarkLine } from '../chart-markline';

import {
  COMBINED_CHART_GRID_HEIGHT,
  COMBINED_CHART_GRID_OFFSET,
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_LEGEND_HEIGHT,
  COMBINED_CHART_RIGHT_OFFSET_PX,
  COMBINED_CHART_X_AXIS_LABEL_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_BOTTOM,
  COMBINED_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT,
  COMBINED_CHART_X_AXIS_ZOOM_HEIGHT,
} from './combined-chart.config';
import { CombinedSeries, IDToCombinedSeriesMap } from './combined-chart.definitions';

export function getGridEchartsConfig(amountOfLines: number) {
  return {
    // reserve space for each y-axis on the left of the chart
    left: amountOfLines * COMBINED_CHART_LEFT_OFFSET_PX,
    top: COMBINED_CHART_LEGEND_HEIGHT + COMBINED_CHART_GRID_OFFSET,
    right: COMBINED_CHART_RIGHT_OFFSET_PX,
    height: COMBINED_CHART_GRID_HEIGHT,
  };
}

export function getLegendEchartsConfig(
  series: Array<Pick<CombinedSeries, 'id' | 'title' | 'legendOverwrite' | 'color'>>,
) {
  // map series ids to series, used to calculate legend text
  const idToEntryMap = Object.fromEntries(series.map((entry) => [entry.id, entry]));

  const data = sortLegendForActualAndReferenceTogether([...series]).map((entry) => ({
    name: entry.id,
    itemStyle: {
      color: entry.color,
    },
    lineStyle: {
      color: entry.color,
    },
  }));

  return {
    data,
    formatter: (id: SeriesId) => {
      const { legendOverwrite, title } = idToEntryMap[id];
      return legendOverwrite ?? title;
    },
    height: COMBINED_CHART_LEGEND_HEIGHT,
  };
}

export function getSeriesEchartsConfig(
  data: Record<GroupName, CombinedSeries[]>,
  markLines: MarkLine[],
  dataset: DataSet[],
): EChartSeriesConfig[] {
  const series = Object.values(data).flatMap((seriesArray, gridIndex) =>
    seriesArray.map((entry: CombinedSeries) => ({
      // has to be unique
      name: entry.id,
      type: 'line',
      lineStyle: {
        type: entry.lineType || 'line',
        width: entry.lineWidth || 2,
      },
      xAxisIndex: 0,
      yAxisIndex: gridIndex,
      datasetIndex: dataset.findIndex((item) => entry.id === item.id),
      animation: false,
      showSymbol: false,
      color: entry.color,
    })),
  );

  series.sort((i) => (i.name.includes(REFERENCE_CURVE_POSTFIX) ? -1 : 1));

  const markLineSeries = getMarkLineSeries(markLines);

  return markLines.length > 0 ? [...series, markLineSeries] : series;
}

export function getCombinedChartLayout(numberOfXAxis: number) {
  const rows = [
    COMBINED_CHART_LEGEND_HEIGHT,
    COMBINED_CHART_GRID_OFFSET,
    COMBINED_CHART_GRID_HEIGHT,
    COMBINED_CHART_X_AXIS_LABEL_HEIGHT,
    COMBINED_CHART_X_AXIS_ZOOM_HEIGHT +
      COMBINED_CHART_X_AXIS_ZOOM_BOTTOM +
      COMBINED_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT,
  ]
    .map((i) => `${i}px`)
    .join(' ');

  const cols = [
    `${COMBINED_CHART_LEFT_OFFSET_PX * numberOfXAxis}px`,
    '1fr',
    `${COMBINED_CHART_RIGHT_OFFSET_PX}px`,
  ].join(' ');

  return { rows, cols };
}

export const createIdToDataMap = (series: CombinedSeries[]): IDToCombinedSeriesMap =>
  Object.fromEntries(series.map((s) => [s.id, s]));
