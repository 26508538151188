import { REFERENCE_CURVE_POSTFIX } from '../../../monitoring/scenes/setup-details/setup-details-content/setup-details-content.helpers';

// eslint-disable-next-line import/prefer-default-export -- helper file
export const sortLegendForActualAndReferenceTogether = <Series extends { title: string }>(
  seriesArray: Series[],
): Series[] =>
  seriesArray.flatMap((series) => {
    if (series.title.endsWith(REFERENCE_CURVE_POSTFIX)) {
      return [];
    }

    return [
      series,
      ...seriesArray.filter(
        (i) => i.title.startsWith(series.title) && i.title.endsWith(REFERENCE_CURVE_POSTFIX),
      ),
    ];
  });
