export const SPLIT_CHART_LEFT_OFFSET_PX = 0;
export const SPLIT_CHART_RIGHT_OFFSET_PX = 35;

export const SPLIT_CHART_Y_AXIS_LABEL_DECIMALS = 2;
export const SPLIT_CHART_Y_AXIS_FONT_SIZE = 12;
export const SPLIT_CHART_X_AXIS_LABEL_HEIGHT = 90;
export const SPLIT_CHART_ZOOM_OFFSET_PX = 85;

/** Width and height of the button to reset the zoom level. */
export const SPLIT_CHART_RESET_ZOOM_BUTTON_DIMENSIONS = { width: 20, height: 20 };
export const SPLIT_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT = 4;
export const SPLIT_CHART_X_AXIS_ZOOM_BOTTOM = 20;
export const SPLIT_CHART_X_AXIS_ZOOM_HEIGHT = 40;

export const SPLIT_CHART_GRID_HEIGHT = 270;
export const SPLIT_CHART_GRID_OFFSET_LEFT = 85;
export const SPLIT_CHART_GRID_OFFSET = 30;
export const SPLIT_CHART_LEGEND_HEIGHT = 24;

export const SPLIT_CHART_TOOLBOX_HEIGHT = 35;
export const SPLIT_CHART_TITLE_HEIGHT = 35;

export const SPLIT_CHART_TOTAL_HEIGHT =
  SPLIT_CHART_TITLE_HEIGHT +
  SPLIT_CHART_LEGEND_HEIGHT +
  SPLIT_CHART_GRID_OFFSET +
  SPLIT_CHART_GRID_HEIGHT +
  SPLIT_CHART_X_AXIS_LABEL_HEIGHT +
  SPLIT_CHART_X_AXIS_ZOOM_HEIGHT +
  SPLIT_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT +
  SPLIT_CHART_X_AXIS_ZOOM_BOTTOM;
