import React, { useState } from 'react';
import { List } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';

import { DataTrackItem, DataTrackListProps } from './data-track-list.definitions';
import DataTrackListItem from './data-track-list-item';
import filterItems from './data-track-list.helpers';
import DataTrackListHeader from './data-track-list-header';

/**
 * A list component used to display datatracks of a process record or a running setup.
 * Allows users to select and deselect datatracks by clicking on them.
 * Shows the displayName of the datatrack along with its engineeringUnit.
 * In process records comparison view, it highlights which process records lack the datatrack by rendering circles
 * with the color of the process records that do have the data track.
 * Allows for searching in the datatracks through a searchbox.
 */
function DataTrackList({
  items,
  selectedItems = [],
  disabledItems = [],
  onSelect,
  onDeselectAll,
  onReset,
  actions,
  showTitle,
  notification,
  canDeleteCustomDataTracks = false,
  onDelete,
  onSave,
  isCustomDataPoints,
  onCreateCustomDataPoints,
}: DataTrackListProps) {
  const logger = useLogger();
  const [searchParam, setSearchParam] = useState<string>('');

  const dataTrackList = filterItems(items, searchParam);

  const handleSearch = (value: string) => {
    const searchText = value.trim();
    setSearchParam(searchText);

    logger.trackEvent(TrackedEvent.SearchForDataTracks);
  };

  const handleSelect = (type: string, checked: boolean) => {
    onSelect?.(type, checked);

    logger.trackEvent(TrackedEvent.SelectOrUnselectDataTrack);
  };

  const handleDelete = (dataTrackItem: DataTrackItem) => {
    onDelete?.(dataTrackItem);

    logger.trackEvent(TrackedEvent.DeleteCustomDataTrack);
  };

  const isDataTracks = Boolean(items.length);
  const isDataTrackList = Boolean(dataTrackList.length);

  return (
    <List
      className="flex flex-auto flex-col overflow-x-hidden rounded-sm bg-white max-md:mt-0"
      data-testid="data-track-list"
    >
      <List.Header>
        <DataTrackListHeader
          searchParam={searchParam}
          disabled={!isDataTracks}
          isDeselectAllDisabled={selectedItems.length === 0}
          onSearch={handleSearch}
          onDeselectAll={onDeselectAll}
          onReset={() => onReset?.()}
          actions={actions}
          showTitle={showTitle}
          notification={notification}
        />
      </List.Header>
      <List.Items className="overflow-auto">
        {isDataTrackList &&
          dataTrackList.map((dataTrack) => (
            <DataTrackListItem
              key={dataTrack.dataTrackType}
              trackInfo={dataTrack}
              isSelected={selectedItems.includes(dataTrack.dataTrackType)}
              isDisabled={disabledItems.includes(dataTrack.dataTrackType)}
              onSelect={handleSelect}
              isDeletable={
                canDeleteCustomDataTracks && dataTrack.isCustom && dataTrack.isSaved !== false
              }
              onDelete={handleDelete}
              isSavable={dataTrack.isSaved === false}
              onSave={onSave}
              isCreateDataPoints={isCustomDataPoints && dataTrack.isCustom}
              onCreateCustomDataPoints={onCreateCustomDataPoints}
            />
          ))}
        {!isDataTrackList && isDataTracks && (
          <div className="py-2" data-testid="data-track-list-message">
            <FormattedMessage
              description="data track list: No matching data tracks"
              defaultMessage="No matching data tracks."
              id="Qjc3nr"
            />
          </div>
        )}
      </List.Items>
    </List>
  );
}

export default DataTrackList;
