import useForceRerender from '../../common/hooks/use-force-rerender';
import {
  ACCUMULATIVE_DAY_SPLIT_NUMBER,
  ACCUMULATIVE_HOUR_SPLIT_NUMBER,
  ACCUMULATIVE_SPLIT_NUMBER,
  ChartXAxisTimeStampFormat,
  DAY_IN_MILLISECONDS,
  HOUR_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
  SECOND_IN_MILLISECONDS,
} from '../chart-formatters/chart-formatters.definitions';

import {
  CHART_ALL_AXIS_SLIDERS,
  CHART_X_AXIS_ZOOM_SLIDER_ID,
  CHART_Y_AXIS_ZOOM_SLIDER_ID,
  DataZoomParam,
  ZoomRange,
} from './chart-zoom.definitions';

/**
 * Resets all given zoom levels to the initial range 0% to 100%.
 *  A rerender is forced after resetting the zoom levels to update the chart visualization.
 */
export function useResetZoom(zoomStates: Array<React.MutableRefObject<ZoomRange>>) {
  const rerender = useForceRerender();

  return () => {
    zoomStates.forEach((state) => {
      // eslint-disable-next-line no-param-reassign -- mutation of an object reference
      state.current = { start: 0, end: 100 };
    });

    rerender();
  };
}

/**
 * Provides a callback that sets the current state of the zoom levels.
 *  It needs to be added to the echarts callbacks as follow:
 *
 * @example
 *    import { init } from 'echarts';
 *
 *    const root = useRef<HTMLDivElement>(null);
 *    const chart = init(root.current)
 *    chart?.on('datazoom', onZoomCallback);
 */
export function getOnZoomCallback(
  zoomX: React.MutableRefObject<ZoomRange>,
  zoomY: React.MutableRefObject<ZoomRange>,
) {
  function onZoomCallback({ start, end, dataZoomId, batch }: DataZoomParam) {
    if (dataZoomId === CHART_X_AXIS_ZOOM_SLIDER_ID) {
      // eslint-disable-next-line no-param-reassign -- ref
      zoomX.current = { start, end };
      return;
    }

    if (dataZoomId === CHART_Y_AXIS_ZOOM_SLIDER_ID) {
      // eslint-disable-next-line no-param-reassign -- ref
      zoomY.current = { start, end };
      return;
    }

    if (batch) {
      const last = batch[batch.length - 1];

      // eslint-disable-next-line no-param-reassign -- ref
      zoomX.current = {
        start: last.start ?? zoomX.current.start,
        end: last.end ?? zoomX.current.end,
      };
      return;
    }

    if (dataZoomId === CHART_ALL_AXIS_SLIDERS) {
      // eslint-disable-next-line no-param-reassign -- ref
      zoomX.current = { start, end };
      // eslint-disable-next-line no-param-reassign -- ref
      zoomY.current = { start, end };
      return;
    }

    throw new TypeError(
      `Echart on zoom callback: Unexpected Input dataZoomId=${dataZoomId} batch=${batch}`,
    );
  }

  return onZoomCallback;
}

export function getMinValueSpan(timeStampFormat: ChartXAxisTimeStampFormat) {
  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeDay) {
    return DAY_IN_MILLISECONDS;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeDay) {
    return HOUR_IN_MILLISECONDS * ACCUMULATIVE_DAY_SPLIT_NUMBER;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.StaticAccumulativeHour) {
    return HOUR_IN_MILLISECONDS;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.AccumulativeHour) {
    return MINUTE_IN_MILLISECONDS * ACCUMULATIVE_HOUR_SPLIT_NUMBER;
  }

  if (timeStampFormat === ChartXAxisTimeStampFormat.Accumulative) {
    return SECOND_IN_MILLISECONDS * ACCUMULATIVE_SPLIT_NUMBER;
  }

  return undefined;
}
