import { ApplicationInsights, Snippet } from '@microsoft/applicationinsights-web';
import { ReactNode } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export type InsightsProps = {
  children: (applicationInsights: ApplicationInsights, reactPlugin: ReactPlugin) => ReactNode;
  applicationInsightsInstrumentationKey: string;
  isCookieEnabled: boolean;
};

export const reactPlugin = new ReactPlugin();

export const applicationInsightsConfig: Snippet['config'] = {
  // config
  maxBatchInterval: 0,
  enableCorsCorrelation: false,
  enableRequestHeaderTracking: false,
  enableResponseHeaderTracking: false,
  correlationHeaderExcludedDomains: [],
  // no fetch tracking on local development
  disableFetchTracking: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  extensions: [reactPlugin],
  // suggested to be set to false
  // see https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
  enableAutoRouteTracking: true,
  autoTrackPageVisitTime: true,
  //
  connectionString: 'UNSPECIFIED',
};

export const createApplicationInsights = (
  applicationInsightsInstrumentationKey: string,
  isCookieEnabled = false,
) => {
  const instance = new ApplicationInsights({
    config: {
      ...applicationInsightsConfig,
      connectionString: applicationInsightsInstrumentationKey,
      disableCookiesUsage: isCookieEnabled === false,
    },
  });

  instance.loadAppInsights();

  return instance;
};

/**
 * key of the cookie set by AppInsights to track the user
 */
export const APP_INSIGHTS_USER_COOKIE_KEY = 'ai_user';

/**
 * key of the cookie set by AppInsights to track the user session
 */
export const APP_INSIGHTS_SESSION_COOKIE_KEY = 'ai_session';
