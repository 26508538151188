const enum TrackedEvent {
  /**
   *
   */
  SendFeedback = 'Send Feedback',

  /**
   *
   */
  Analytics = 'Analytics',

  /**
   *
   */
  Welcome = 'Welcome',

  /**
   *
   */
  Monitoring = 'Monitoring',

  /**
   *
   */
  OrganizationManagement = 'Organization Management',

  /**
   *
   */
  UserManagement = 'User Management',

  /**
   *
   */
  UploadControlProcedureFile = 'Upload control procedure file',

  /**
   *
   */
  ConfirmUpload = 'Uploading File Confirmed',

  /**
   *
   */
  SearchForProcessRecords = 'Search for process records',

  /**
   *
   */
  Compare = 'Compare',

  /**
   *
   */
  SelectProcessRecordFromList = 'Select process record from list',

  /**
   *
   */
  SelectProcessRecordForComparison = 'Select process record for comparison',

  /**
   *
   */
  DeleteProcessRecord = 'Delete Process Record',

  /**
   *
   */
  GoToProcessRecords = 'Go to process records',

  /**
   *
   */
  SearchForDataTracks = 'Search for data tracks',

  /**
   *
   */
  Add = 'Add',

  /**
   *
   */
  UploadCustomDataTrack = 'Upload custom data track',

  /**
   *
   */
  SelectOrUnselectDataTrack = 'Select / Unselect data track',

  /**
   *
   */
  DeselectAll = 'Deselect All',

  /**
   *
   */
  ResetDataTrackSelection = 'Reset',

  /**
   *
   */
  UpdateOrDeleteAnAttribute = 'Update or delete an attribute',

  /**
   *
   */
  ToggleSplitOrCombinedChart = 'Toggle split/combined chart',

  /**
   *
   */
  SelectSingleUnit = 'Select single unit',

  /**
   *
   */
  Reload = 'Reload',

  /**
   *
   */
  GoBackToMonitoringOverview = 'Go Back to Monitoring Overview',

  /**
   *
   */
  NavigateBetweenUnits = 'Navigate between units',

  /**
   *
   */
  SelectReference = 'Select Reference',

  /**
   *
   */
  ConfirmSelectingReferenceCurve = 'Confirm selecting reference curve',

  /**
   *
   */
  StartRemovingReferenceCurve = 'Start removing reference curve',

  /**
   *
   */
  AlignReferenceCurveToInoculationTime = 'Align Reference Curve to Inoculation Time',

  /**
   *
   */
  AlignReferenceCurveToStartTime = 'Align Reference Curve to Start Time',

  /**
   *
   */
  ExportGraph = 'Export graph as image',

  /**
   *
   */
  AddDataTracksToMonitoringOverview = 'Add data tracks to Monitoring Overview',

  /**
   *
   */
  TabIntoDataHow = 'Tab into DataHow',

  /**
   *
   */
  TransferToDataHow = 'Transfer to DataHow',

  /**
   *
   */
  TimeStampFormat = 'Timestamp Format',

  /**
   *
   */
  AlignProcessRecord = 'Align Process Record',

  /**
   *
   */
  ZoomingInTheGraph = 'Zooming in the Graph',

  /**
   *
   */
  ChangeDataHowMapping = 'Change DataHow Mapping',

  /**
   *
   */
  ToggleDataTrackOnMonitoringOverview = 'Toggle data track on Monitoring Overview Filter',

  /**
   *
   */
  RemoveDataTrackOnMonitoringOverview = 'Remove data track from Monitoring Overview Filter',

  /**
   *
   */
  DeleteWorkflow = 'Delete Workflow from Monitoring Overview',

  /**
   *
   */
  LearnMoreAboutDWSConnection = 'Learn More About DWS Connection',

  /**
   *
   */
  SelectTimeSpan = 'Select Time span',

  /**
   *
   */
  ConsentToAppInsightCookie = 'Consent to AppInsights cookie',

  /**
   *
   */
  RevokeConsentToAppInsightCookie = 'Revoke consent to AppInsights cookie',

  /**
   *
   */
  AddCustomDataTrack = 'Add custom data track',

  /**
   *
   */
  DeleteCustomDataTrack = 'Delete custom data track',

  /**
   *
   */
  AddDataPointsToCustomDataTrack = 'Add data points to custom data track',

  /**
   *
   */
  DeleteDataPointsFromCustomDataTrack = 'Delete data points from custom data track',

  /**
   *
   */
  SetYAxisRange = 'Set Y-Axis Range',

  /**
   *
   */
  SetYAxisRangeInMonitoringOverview = 'Set Y-Axis Range in Monitoring Overview',

  /**
   *
   */
  SubscribeToDWCEvents = 'Subscribe To DWC Events',

  /**
   *
   */
  UnsubscribeFromDWCEvents = 'Unsubscribe From DWC Events',

  /**
   *
   */
  DownloadManual = 'Download Manual',

  /**
   *
   */
  ExpandOrCollapsePanel = 'Expand / Collapse Panel',

  /**
   *
   */
  ExportProcessRecord = 'Export Process Record',
}

export default TrackedEvent;
