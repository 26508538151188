import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, ChartsIcon, VesselIcon, DocumentIcon } from '@biss/react-horizon-web';

import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';

/**
 * Scene to show when a user tries to open a route that is not available.
 *  It allows the user to redirect to the base scene.
 */
function PageNotFound(): FunctionComponentElement<unknown> {
  const intl = useIntl();
  const logger = useLogger();

  const messages = defineMessages({
    analytics_desc: {
      id: 'sjU2lW',
      description: 'Description of link to analytics in 404-Page',
      defaultMessage: 'Organize and plot your process records.',
    },
    monitoring_desc: {
      id: 'PPFjGr',
      description: 'Description of link to monitoring in 404-Page',
      defaultMessage: 'Monitor your ongoing runs.',
    },
    manual_desc: {
      id: 'M9xSdY',
      description: 'Description of link to the software manual in 404-Page',
      defaultMessage: 'Software manual for the BioNsight cloud.',
    },
    analytics: {
      id: 'voNyqr',
      description: 'Title of link to analytics in 404-Page',
      defaultMessage: 'Analytics',
    },
    monitoring: {
      id: '6cLta4',
      description: 'Title of link to monitoring in 404-Page',
      defaultMessage: 'Monitoring',
    },
    manual: {
      id: 'MiSwF6',
      description: 'Title of link to the software manual in 404-Page',
      defaultMessage: 'Software Manual',
    },
  });

  const links = [
    {
      name: intl.formatMessage(messages.analytics),
      href: '/analytics',
      description: intl.formatMessage(messages.analytics_desc),
      icon: ChartsIcon,
    },
    {
      name: intl.formatMessage(messages.monitoring),
      href: '/monitoring',
      description: intl.formatMessage(messages.analytics_desc),
      icon: VesselIcon,
    },
    {
      name: intl.formatMessage(messages.manual),
      href: '/assets/documents/bionsight_manual_en.pdf',
      description: intl.formatMessage(messages.manual_desc),
      icon: DocumentIcon,
      onClick: () => {
        logger.trackEvent(TrackedEvent.DownloadManual);
      },
    },
  ];

  return (
    <>
      <div className="mx-auto mt-24 max-w-2xl text-center sm:mt-24" data-testid="PageNotFound">
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- Error number, no translation needed */}
        <p className="text-base font-semibold leading-8 text-blue-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          <FormattedMessage
            description="This page does not exist."
            defaultMessage="This page does not exist."
            id="AOL3gW"
          />
        </h1>
        <p className="text-gray-500 mt-4 text-base leading-7 sm:mt-6 sm:text-lg sm:leading-8">
          <FormattedMessage
            description="Sorry, we couldn’t find the page you’re looking for."
            defaultMessage="Sorry, we couldn’t find the page you’re looking for."
            id="ZKwewk"
          />
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <h2 className="sr-only">
          <FormattedMessage
            description="Screenreader description of the list of links on the 404 page"
            defaultMessage="Popular pages."
            id="85kAbm"
          />
        </h2>
        <ul className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
          {links.map((link) => (
            <li key={link.name} className="relative flex gap-x-6 py-6">
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <link.icon aria-hidden="true" className="h-6 w-6 text-blue-600" />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  <a href={link.href} onClick={link.onClick}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {link.name}
                  </a>
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-700">{link.description}</p>
              </div>
              <div className="flex-none self-center">
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 text-gray-600" />
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-10 flex justify-center">
          <Link to="/" id="Base" data-testid="PageNotFound-homelink">
            <FormattedMessage
              description="Link to the Home Page"
              defaultMessage="Go Back Home"
              id="knhtvR"
            />
          </Link>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
