import {
  SPLIT_CHART_GRID_HEIGHT,
  SPLIT_CHART_GRID_OFFSET,
  SPLIT_CHART_GRID_OFFSET_LEFT,
  SPLIT_CHART_LEGEND_HEIGHT,
  SPLIT_CHART_RIGHT_OFFSET_PX,
  SPLIT_CHART_TITLE_HEIGHT,
  SPLIT_CHART_TOOLBOX_HEIGHT,
  SPLIT_CHART_X_AXIS_LABEL_HEIGHT,
  SPLIT_CHART_X_AXIS_ZOOM_BOTTOM,
  SPLIT_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT,
  SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
} from '../split-chart.config';
import { EChartSeriesConfig, MarkLine } from '../../chart-markline';
import { getMarkLineSeries } from '../../chart-markline/chart-markline';
import { SplitSeries } from '../split-chart.definitions';
import { SeriesId } from '../../../components/time-series-chart';
import { sortLegendForActualAndReferenceTogether } from '../../chart-legend';

import { IDToSplitSeriesMap } from './split-chart-item.definitions';

export function getGridEchartsConfig() {
  return {
    // reserve space for y-axis on the left of the chart
    left: SPLIT_CHART_GRID_OFFSET_LEFT,
    top: SPLIT_CHART_TITLE_HEIGHT + SPLIT_CHART_LEGEND_HEIGHT + SPLIT_CHART_GRID_OFFSET,
    right: SPLIT_CHART_RIGHT_OFFSET_PX,
    height: SPLIT_CHART_GRID_HEIGHT,
  };
}

export function getLegendEchartsConfig(
  series: Array<Pick<SplitSeries, 'id' | 'title' | 'legendOverwrite' | 'color'>>,
) {
  // map series ids to series, used to calculate legend text
  const idToEntryMap = Object.fromEntries(series.map((entry) => [entry.id, entry]));

  return {
    data: sortLegendForActualAndReferenceTogether([...series]) // copy so the original array is not changed
      .map((entry) => ({
        name: entry.id,
        itemStyle: {
          color: entry.color,
        },
        lineStyle: {
          color: entry.color,
        },
      })),
    formatter: (id: SeriesId) => {
      const { legendOverwrite, title } = idToEntryMap[id];
      return legendOverwrite ?? title;
    },
    top: SPLIT_CHART_TOOLBOX_HEIGHT,
    height: SPLIT_CHART_LEGEND_HEIGHT,
  };
}

export function getSeriesEchartsConfig(
  data: Array<{ color: string; id: string; title: string; lineType?: string; lineWidth?: number }>,
  markLines: MarkLine[],
): EChartSeriesConfig[] {
  const series = data.map<EChartSeriesConfig>((entry, index) => ({
    type: 'line',
    // has to be unique
    name: entry.id,
    lineStyle: {
      type: entry.lineType || 'line',
      width: entry.lineWidth || 2,
    },
    xAxisIndex: 0,
    yAxisIndex: 0,
    datasetIndex: index,
    animation: false,
    showSymbol: false,
    color: entry.color,
  }));

  const markLineSeries = getMarkLineSeries(markLines);

  return markLines.length > 0 ? [...series, markLineSeries] : series;
}

export function getTitleConfig(text: string) {
  return { text, left: 'center' };
}

export function getSplitChartLayout() {
  const rows = [
    SPLIT_CHART_TITLE_HEIGHT,
    SPLIT_CHART_LEGEND_HEIGHT,
    SPLIT_CHART_GRID_OFFSET,
    SPLIT_CHART_GRID_HEIGHT,
    SPLIT_CHART_X_AXIS_LABEL_HEIGHT,
    SPLIT_CHART_X_AXIS_ZOOM_HEIGHT +
      SPLIT_CHART_X_AXIS_ZOOM_BOTTOM +
      SPLIT_CHART_X_AXIS_ZOOM_BRUSH_HEIGHT,
  ]
    .map((i) => `${i}px`)
    .join(' ');

  const cols = [
    `${SPLIT_CHART_GRID_OFFSET_LEFT}px`,
    '1fr',
    `${SPLIT_CHART_RIGHT_OFFSET_PX}px`,
  ].join(' ');

  return { rows, cols };
}

export const createIdToDataMap = (series: SplitSeries[]): IDToSplitSeriesMap =>
  Object.fromEntries(series.map((s) => [s.id, s]));
