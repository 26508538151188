import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icons32, Modal, Tabs } from '@biss/react-horizon-web';

import TrackedEvent from '../../../shared/common/tracked-event';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';

import AddProcessRecordByFile from './add-process-record-by-file';
import CreateProcessRecordViaForm from './create-process-record-via-form';
import { AddProcessRecordModalProps } from './add-process-record-modal.definitions';

function AddProcessRecordModal({
  defaultOpen = false,
  defaultDirty = false,
  defaultTab = 'upload',
}: AddProcessRecordModalProps) {
  const intl = useIntl();

  const logger = useLogger();

  const [isModalOpen, setIsModalOpen] = useState(defaultOpen);

  const [isActiveFormDirty, setIsActiveFormDirty] = useState(defaultDirty);

  const [tab, setTab] = useState<'upload' | 'create'>(defaultTab);

  const handleOpenChange = (open: boolean) => {
    // only react when attempting to close the modal
    if (open) {
      return;
    }

    if (
      // if neither form is dirty skip confirmation
      isActiveFormDirty &&
      // eslint-disable-next-line no-restricted-globals, no-alert -- TODO: replace with a component
      confirm(
        intl.formatMessage({
          defaultMessage:
            'Closing the dialog will discard all the changes made to the form, do you wish to close the dialog anyway?',
          id: 'p+s4iE',
          description: 'Add Process Record: Close Warning',
        }),
      ) === false
    ) {
      return;
    }

    setIsActiveFormDirty(false);
    setIsModalOpen(false);
  };

  const handleTabChange = (tabValue: string) => {
    if (tabValue !== 'upload' && tabValue !== 'create') {
      return;
    }

    if (
      // if neither form is dirty skip confirmation
      isActiveFormDirty &&
      // eslint-disable-next-line no-restricted-globals, no-alert -- TODO: replace with a component
      confirm(
        intl.formatMessage({
          defaultMessage:
            'Switching tabs will discard all the changes made to the form, do you wish to switch tabs anyways?',
          id: 'zmur1l',
          description: 'Add Process Record: Tab Warning',
        }),
      ) === false
    ) {
      return;
    }

    setIsActiveFormDirty(false);
    setTab(tabValue);
  };

  const handleFileSubmit = () => {
    setIsActiveFormDirty(false);
    logger.trackEvent(TrackedEvent.ConfirmUpload);
  };

  const handleFileChange = () => {
    setIsActiveFormDirty(true);
  };

  const handleFormChange = () => {
    setIsActiveFormDirty(true);
  };

  const handleFormSubmit = () => {
    setIsActiveFormDirty(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
    logger.trackEvent(TrackedEvent.UploadControlProcedureFile);
  };

  return (
    <>
      <Button
        data-testid="add-process-record-modal-button"
        className="md:hidden"
        kind="secondary"
        leftIcon={<Icons32.DocumentNewIcon />}
        onClick={handleClick}
      />
      <Button
        data-testid="add-process-record-modal-button"
        className="hidden md:block"
        kind="secondary"
        onClick={handleClick}
      >
        <FormattedMessage
          defaultMessage="Add Process Record"
          description="Add Process Record: Modal Trigger"
          id="skcoXz"
        />
      </Button>

      <Modal
        // called with false when the close button is clicked
        onOpenChange={handleOpenChange}
        open={isModalOpen}
        title={intl.formatMessage({
          defaultMessage: 'Create New Process Record',
          description: 'Add Process Record: Modal Title',
          id: 'dL1S8V',
        })}
      >
        <Tabs
          className="sticky top-20"
          activationMode="manual"
          value={tab}
          onValueChange={handleTabChange}
        >
          <Tabs.List>
            <Tabs.Trigger value="upload" data-testid="add-process-record-upload-tab">
              <FormattedMessage
                defaultMessage="From DWC Export File"
                description="Add Process Record Tabs: From File"
                id="Q1w7NM"
              />
            </Tabs.Trigger>

            <Tabs.Trigger value="create" data-testid="add-process-record-create-tab">
              <FormattedMessage
                defaultMessage="Without a File"
                description="Add Process Record Tabs: Without File"
                id="OWrDYU"
              />
            </Tabs.Trigger>
          </Tabs.List>
        </Tabs>

        {tab === 'upload' && (
          <AddProcessRecordByFile onChange={handleFileChange} onSubmit={handleFileSubmit} />
        )}

        {tab === 'create' && (
          <CreateProcessRecordViaForm onChange={handleFormChange} onSubmit={handleFormSubmit} />
        )}
      </Modal>
    </>
  );
}

export default AddProcessRecordModal;
