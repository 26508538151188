import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

import { ChevronDownUpIcon, ChevronUpDownIcon } from '@biss/react-horizon-web';

import { CHART_VIEW, ChartView } from './chart-toolbox.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file
export function renderIcon(intl: IntlShape, chartOption: ChartView) {
  const iconMap: Record<ChartView, ReactNode> = {
    [CHART_VIEW[0]]: (
      <ChevronDownUpIcon
        title={intl.formatMessage({
          defaultMessage: 'Combined View',
          id: 'sox1ui',
          description: 'Chart Toggle button: Combined View icon title',
        })}
      />
    ),
    [CHART_VIEW[1]]: (
      <ChevronUpDownIcon
        title={intl.formatMessage({
          defaultMessage: 'Split View',
          id: 'fe/Ec7',
          description: 'Chart Toggle button: Split View icon title',
        })}
      />
    ),
  };
  return iconMap[chartOption];
}
