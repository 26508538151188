import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ChevronLeftIcon, PageLayout, PlusIcon } from '@biss/react-horizon-web';
import { useQueryClient } from '@tanstack/react-query';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';

import QKey from '../../../shared/common/hooks/keys';
import useSetup from '../../common/hooks/use-setup';
import RouteDefinition from '../../../shared/common/routes/routes.definitions';
import { ProcessRecordId } from '../../../shared/common/types/process-record';
import {
  DataTrackBase,
  DataTrackDescriptor,
  DataTrackType,
} from '../../../shared/common/types/setup/data-track';
import DataTrackList from '../../../shared/components/data-track-list';
import { SeriesLegendSelected } from '../../../shared/components/time-series-chart/time-series-chart.definitions';
import useSetupList from '../../common/hooks/use-setup-list';
import { ErrorMessage } from '../../../shared/components/error-message';
import { GenericError } from '../../../shared/common/types/generic-error';
import { ApiError } from '../../../shared/common/types/api-error/api-error';
import Footer from '../../../shared/components/footer';
import useAutomaticUpdates from '../../common/hooks/use-automatic-updates';
import useDocumentVisibility from '../../../shared/common/hooks/use-document-visibility';
import { MONITORING_DATA_TRACKS, RETRY_DELAY } from '../../common/types/setup';
import { getIsSetupSetting } from '../../common/helpers';
import { ClientError } from '../../../shared/common/types/client-error';
import useSaveDataTracks from '../../common/hooks/use-save-data-tracks';
import DataTrackCreateModal from '../../../shared/components/data-track-create-modal';
import { DataTrackItem } from '../../../shared/components/data-track-list/data-track-list.definitions';
import useDeleteDataTrack from '../../common/hooks/use-delete-data-track';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import { DEFAULT_FRACTIONAL_DIGITS } from '../../common/types/data-track';

import {
  getRequestedDataTrackIds,
  deleteDataTrack,
  getDataTrackColorName,
  updateDataTrackColors,
  getWorkflowInfo,
  getSelectedDataTracks,
  getRequestedDataTrackId,
  getDataTracksConflict,
  handleDeleteCustomDataTrackSuccess,
  getDataTrackId,
  getDataTracksDescriptors,
  isInvalidateSetup,
  getStopTimestamp,
  findDataTrackByType,
} from './setup-details.helpers';
import TestStream from './test-stream';
import SetupNavigation from './setup-navigation';
import { WorkflowInfo } from './setup-navigation/setup-navigation.definitions';
import SetupDetailsContent from './setup-details-content';
import SetupEvents from './setup-events';
import CustomDataPoints from './custom-data-points';
import { SetupDetailsProps } from './setup-details.definitions';

function SetupDetails({ defaultSidebarOpen }: SetupDetailsProps) {
  const intl = useIntl();
  const logger = useLogger();

  const { id } = useParams();
  const navigate = useNavigate();
  const isDocumentVisible = useDocumentVisibility();
  const queryClient = useQueryClient();
  const autoUpdate = useAutomaticUpdates();
  const storageDataTracks = useReadLocalStorage<Record<string, boolean> | null>(
    MONITORING_DATA_TRACKS,
  );
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [referenceProcessId, setReferenceProcessId] = useState<ProcessRecordId>('');
  const isDocumentVisiblePrev = useRef<boolean>(isDocumentVisible);

  // controls whether the sidebar is open or closed
  const [open, setOpen] = useState(defaultSidebarOpen ?? !isMobile);

  if (id === undefined) {
    throw new Error('An id has to be provided via the route.');
  }

  const { data: setup, isError, error, isLoading } = useSetup(id);
  const { data: setupItems } = useSetupList(autoUpdate);
  const { mutate: deleteCustomDataTrack } = useDeleteDataTrack(
    setup?.controlProcedureId as string,
    id,
  );
  const updatedSetup = setupItems?.find((item) => item.setupId === id);
  const isDisconnected = updatedSetup?.connectionState === 'Disconnected';

  const [selectedDataTracks, setSelectedDataTracks] = useState<Record<DataTrackType, string>>(
    getSelectedDataTracks(storageDataTracks),
  );

  const [seriesLegendSelected, setSeriesLegendSelected] = useState<SeriesLegendSelected>({});
  const [workflowInfo, setWorkflowInfo] = useState<WorkflowInfo>();
  const [isLoadingSetup, setIsLoadingSetup] = useState<boolean>(true);
  const [isDataPointsModalOpen, setIsDataPointsModalOpen] = useState<boolean>(false);
  const [selectedCustomDataTrack, setSelectedCustomDataTrack] = useState<DataTrackDescriptor>();
  const {
    mutate: saveDataTracks,
    error: saveDataTracksError,
    isPending,
    isSuccess,
    reset,
  } = useSaveDataTracks(id);

  // set workflow info when receive list of setups and setup details
  useEffect(() => {
    if (workflowInfo) {
      return;
    }
    const workflow = getWorkflowInfo(setupItems, setup?.workflowId);
    setWorkflowInfo(workflow);
  }, [setupItems, setup?.workflowId]);

  // invalidate queries if document is visible after hiding and auto update is disabled
  useEffect(() => {
    if (isInvalidateSetup(isDocumentVisiblePrev.current, isDocumentVisible, autoUpdate)) {
      setIsLoadingSetup(true);
      queryClient.invalidateQueries({ queryKey: [QKey.SETUPS] });
    }
    isDocumentVisiblePrev.current = isDocumentVisible;
  }, [isDocumentVisible]);

  const requestedDataTrackIds = getRequestedDataTrackIds(
    Object.keys(selectedDataTracks),
    setup?.dataTracks,
  );

  const onReferenceProcessRecord = (processRecordId: ProcessRecordId): void => {
    setReferenceProcessId(processRecordId);
  };

  function setDataTypeSelection(types: Record<DataTrackType, string>): void {
    setSelectedDataTracks(types);
  }

  const updateIsLoadingSetup = (value: boolean) => {
    if (!isLoading) {
      setIsLoadingSetup(value);
    }
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const isSetupSetting = getIsSetupSetting(setup?.startTimestamp);
    if (isSetupSetting) {
      // invalidate setup details every 1 min for the first 5 min after setup start time
      timeout = setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QKey.SETUPS, id],
        });
      }, RETRY_DELAY);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setup]);

  const onReset = () => {
    logger.trackEvent(TrackedEvent.ResetDataTrackSelection);
    const selectedTracks = getSelectedDataTracks(storageDataTracks);
    setDataTypeSelection(selectedTracks);
    setSeriesLegendSelected({});
    const dataTrackIds = getRequestedDataTrackIds(Object.keys(selectedTracks), setup?.dataTracks);
    dataTrackIds?.forEach((trackId: string) => {
      queryClient.invalidateQueries({ queryKey: [QKey.SETUPS, id, trackId] });
    });
  };

  const onDeselectAll = () => {
    logger.trackEvent(TrackedEvent.DeselectAll);
    setDataTypeSelection({});
    setSeriesLegendSelected({});
  };

  const setDataTrackSelected = (type: ProcessRecordId, selected: boolean) => {
    if (selected) {
      const trackId = getRequestedDataTrackId(type, setup?.dataTracks);
      queryClient.removeQueries({ queryKey: [QKey.SETUPS, id, trackId] });
      const color = getDataTrackColorName(type, selectedDataTracks);
      setSelectedDataTracks((prevState) => ({ ...prevState, [type]: color }));
    } else {
      setSelectedDataTracks((prevState) => deleteDataTrack(prevState, type));
    }
  };

  const updateSelectedDataTrackColors = () => {
    setSelectedDataTracks(updateDataTrackColors(selectedDataTracks));
  };

  const onChangeSetup = (value: string) => {
    setIsLoadingSetup(true);
    queryClient.removeQueries({ queryKey: [QKey.SETUPS, id] });

    navigate(`${RouteDefinition.Monitoring}/setups/${value}`, {
      relative: 'path',
      replace: true,
    });
  };

  const dataTracks = getDataTracksDescriptors(setup?.dataTracks);

  // delete a data track from setup
  const handleDelete = ({ dataTrackType }: DataTrackItem) => {
    // find the data track that has the same type as the one that is supposed to be deleted
    const dataTrackId = getDataTrackId(dataTracks, dataTrackType);

    deleteCustomDataTrack(dataTrackId, {
      onSuccess: () =>
        handleDeleteCustomDataTrackSuccess(
          dataTrackType,
          selectedDataTracks,
          setSelectedDataTracks,
        ),
    });

    logger.trackEvent(TrackedEvent.DeleteCustomDataTrack);
  };

  const goToOverview = () => {
    logger.trackEvent(TrackedEvent.GoBackToMonitoringOverview);
    queryClient.removeQueries({ queryKey: [QKey.SETUPS] });
    navigate(`${RouteDefinition.Monitoring}/setups`);
  };

  const onSaveDataTrack = (dataTracksCustom: DataTrackBase[]) => {
    if (setup?.controlProcedureId && dataTracksCustom.length) {
      const tracks = dataTracksCustom.map((item) => ({
        ...item,
        fractionalDigits: DEFAULT_FRACTIONAL_DIGITS,
      }));
      saveDataTracks({
        dataTracks: tracks,
        controlProcedureId: setup.controlProcedureId,
      });
      logger.trackEvent(TrackedEvent.AddCustomDataTrack);
    }
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    if (isOpen === false) {
      reset(); // reset mutation so isSuccess is false again
    }
  };

  const dataTracksConflict = getDataTracksConflict(saveDataTracksError);

  const onCreateCustomDataPoints = (dataTrackType: DataTrackType) => {
    const track = findDataTrackByType(dataTracks, dataTrackType);
    if (track) {
      setSelectedCustomDataTrack(track);
      setIsDataPointsModalOpen(true);
    }
  };

  const onChangeDataPointsModalOpen = (isOpen: boolean) => {
    if (!isOpen) {
      setSelectedCustomDataTrack(undefined);
      setIsDataPointsModalOpen(false);
    }
  };
  const stopTimestamp = getStopTimestamp(setup?.stopTimestamp, updatedSetup?.stopTimestamp);

  const sidebarButtonsText = intl.formatMessage({
    defaultMessage: 'Select Data Tracks',
    id: '8WoedZ',
    description: 'Select Data Tracks Sidebar Open And Close Text',
  });

  const handleSidePanelOpenChange = (isOpen: boolean) => {
    logger.trackEvent(TrackedEvent.ExpandOrCollapsePanel);
    setOpen(isOpen);
  };

  return (
    <PageLayout sidebarOpen={open} className="!h-[calc(100dvh-var(--header-bar-height,0))]">
      <PageLayout.Main>
        <PageLayout.Main.ActionBar
          sidePanelOpen={open}
          onSidePanelOpenChange={handleSidePanelOpenChange}
          openText={sidebarButtonsText}
          closeText={sidebarButtonsText}
          pageTitle={
            <SetupNavigation
              workflowInfo={workflowInfo}
              setupId={id}
              setupInfo={
                setup && {
                  title: setup.title,
                  deviceName: setup.deviceName,
                  unit: setup.unit,
                }
              }
              onChangeSetup={onChangeSetup}
            />
          }
          backButton={
            <Button
              kind="secondary"
              mood="neutral"
              onClick={goToOverview}
              leftIcon={<ChevronLeftIcon />}
              data-testid="go-back-to-overview-button"
              className="self-start"
            >
              <FormattedMessage
                description="Button to Monitoring Overview screen"
                defaultMessage="Back to Overview"
                id="eqyKJG"
              />
            </Button>
          }
        />
        <PageLayout.Main.Content className="flex flex-auto flex-col gap-2 overflow-y-auto">
          <div data-testid="setup-details" className="flex flex-col gap-2 px-4">
            {isError ? (
              <ErrorMessage
                error={error as GenericError<ApiError | ClientError>}
                message="An internal error occurred while loading the running unit."
                variant="highlighted"
              />
            ) : (
              <div className="flex h-full flex-col gap-2">
                <SetupDetailsContent
                  setupId={id}
                  isDisconnected={isDisconnected}
                  autoUpdate={autoUpdate}
                  controlProcedureId={setup?.controlProcedureId}
                  startTimestamp={setup?.startTimestamp}
                  stopTimestamp={stopTimestamp}
                  inoculationTimestamp={updatedSetup?.inoculationTimestamp}
                  requestedDataTrackIds={requestedDataTrackIds}
                  selectedDataTracks={selectedDataTracks}
                  updateDataTrackColors={updateSelectedDataTrackColors}
                  referenceProcessId={referenceProcessId}
                  onReferenceProcessRecord={onReferenceProcessRecord}
                  seriesLegendSelected={seriesLegendSelected}
                  toggleSeriesLegendSelected={setSeriesLegendSelected}
                  isLoadingSetup={isLoadingSetup}
                  updateIsLoadingSetup={updateIsLoadingSetup}
                />
                <SetupEvents
                  controlProcedureId={setup?.controlProcedureId}
                  isSubscribedToDwcEvents={setup?.isSubscribedToDwcEvents}
                  setupId={id}
                  autoUpdate={autoUpdate}
                  systemId={setup?.systemId}
                  unit={setup?.unit}
                  startTimestamp={setup?.startTimestamp}
                  stopTimestamp={stopTimestamp}
                  isLoadingSetup={isLoadingSetup}
                />
                <TestStream />
              </div>
            )}
            <CustomDataPoints
              setupId={setup?.setupId}
              dataTrack={selectedCustomDataTrack}
              open={isDataPointsModalOpen}
              onChangeModal={onChangeDataPointsModalOpen}
              controlProcedureId={setup?.controlProcedureId}
            />
          </div>

          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
      {setup && (
        <PageLayout.Sidebar
          onOpenChange={handleSidePanelOpenChange}
          isOpen={open}
          openText={sidebarButtonsText}
          closeText={sidebarButtonsText}
        >
          <PageLayout.Sidebar.Title className="whitespace-nowrap">
            <FormattedMessage
              description="Data Track Selection Sidebar Title"
              defaultMessage="Data Track Selection"
              id="noSwjL"
            />
          </PageLayout.Sidebar.Title>

          <DataTrackList
            onDeselectAll={onDeselectAll}
            showTitle={false}
            onReset={onReset}
            items={dataTracks}
            selectedItems={Object.keys(selectedDataTracks)}
            onDelete={handleDelete}
            canDeleteCustomDataTracks
            onSelect={setDataTrackSelected}
            isCustomDataPoints
            onCreateCustomDataPoints={onCreateCustomDataPoints}
            actions={
              <DataTrackCreateModal
                dataTrackTypes={[
                  ...dataTracks.map((track) => track.dataTrackType),
                  ...dataTracksConflict,
                ]}
                trigger={
                  <Button
                    kind="secondary"
                    mood="neutral"
                    leftIcon={<PlusIcon />}
                    data-testid="newDataTrackButton"
                  />
                }
                onSave={onSaveDataTrack}
                isError={isError && !dataTracksConflict.length}
                isPending={isPending}
                isDisabled={isPending}
                isSuccess={isSuccess}
                onOpenChange={handleModalOpenChange}
              />
            }
          />
        </PageLayout.Sidebar>
      )}
    </PageLayout>
  );
}

export default SetupDetails;
