import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, PlusIcon, Popover } from '@biss/react-horizon-web';

import DataTrackUploadModal from '../data-track-upload-modal';
import DataTrackFormulaModal from '../data-track-formula-modal';
import DataTrackEmptyModal from '../data-track-empty-modal';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import {
  AddDataTrackPopOverOption,
  AddDataTrackPopOverProps,
} from './add-data-track-pop-over.definitions';

function AddDataTrackPopOver({
  processRecordId,
  defaultOption = undefined,
  defaultOpen = false,
}: AddDataTrackPopOverProps) {
  const logger = useLogger();

  const [modalToOpen, setModalToOpen] = useState<AddDataTrackPopOverOption | undefined>(
    defaultOption,
  );

  const handleItemClick = (val: AddDataTrackPopOverOption) =>
    // the micro-delay is done to mitigate a bug
    // where radix components are focused in and out recursively hanging up the UI thread
    Promise.resolve().then(() => setModalToOpen(val));

  const handleModalOpenChange = (isOpen: boolean) => {
    if (isOpen === false) setModalToOpen(undefined);
  };

  const handlePopoverOpenChange = () => {
    logger.trackEvent(TrackedEvent.AddCustomDataTrack);
  };

  const isUploadModalOpen = modalToOpen === 'file';
  const isFormulaModalOpen = modalToOpen === 'formula';
  const isEmptyModalOpen = modalToOpen === 'empty';

  return (
    <>
      <Popover
        defaultOpen={defaultOpen}
        side="bottom"
        // TODO there is a bug that causes this callback to never be called. Will be fixed in an RHW MR
        onOpenChange={handlePopoverOpenChange}
        trigger={
          <Button
            kind="secondary"
            mood="neutral"
            leftIcon={<PlusIcon />}
            data-testid="add-data-track-button"
          />
        }
      >
        <Popover.Item
          data-testid="add-data-track-from-file-item"
          textValue="file"
          onClick={() => handleItemClick('file')}
        >
          <FormattedMessage
            defaultMessage="From File"
            description="Add new Data Track Option: From File"
            id="0Jyp8q"
          />
        </Popover.Item>

        <Popover.Item
          data-testid="add-data-track-from-formula-item"
          onClick={() => handleItemClick('formula')}
        >
          <FormattedMessage
            defaultMessage="From Formula"
            description="Add new Data Track Option: From Formula"
            id="tlFdX9"
          />
        </Popover.Item>

        <Popover.Item
          data-testid="add-data-track-empty-item"
          onClick={() => handleItemClick('empty')}
        >
          <FormattedMessage
            defaultMessage="From Manual Entry"
            description="Add new Data Track Option: Empty"
            id="3O6tAs"
          />
        </Popover.Item>
      </Popover>

      {isUploadModalOpen && (
        <DataTrackUploadModal
          processRecordId={processRecordId}
          open
          onOpenChange={handleModalOpenChange}
        />
      )}

      {isFormulaModalOpen && (
        <DataTrackFormulaModal
          processRecordId={processRecordId}
          open
          onOpenChange={handleModalOpenChange}
        />
      )}

      {isEmptyModalOpen && (
        <DataTrackEmptyModal
          processRecordId={processRecordId}
          open
          onOpenChange={handleModalOpenChange}
        />
      )}
    </>
  );
}

export default AddDataTrackPopOver;
