import { IntlShape } from 'react-intl';

import { ChartXAxisTimeStampFormat } from '../chart-formatters/chart-formatters.definitions';
import {
  createXAxisTimeStampDateFormatter,
  tooltipAccumulativeExactFormatter,
} from '../chart-formatters';

import { IDToCombinedSeriesMap } from '../combined-chart/combined-chart.definitions';

import { TooltipFormatterParam } from './chart-tooltip.definitions';

export const getTooltipTimeFormatter = (
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) => {
  if (timeStampFormat === ChartXAxisTimeStampFormat.Date) {
    return createXAxisTimeStampDateFormatter(intl);
  }

  return tooltipAccumulativeExactFormatter;
};

// TODO(4460): Refactor to include information about all split chart items
export const getTooltipFormatter = (
  idToSeriesMap: IDToCombinedSeriesMap,
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) => {
  const xAxisFormatter = getTooltipTimeFormatter(timeStampFormat, intl);

  return (params: TooltipFormatterParam[]) => {
    const firstParam = params.at(0);
    if (firstParam === undefined) {
      return '<i>No data</i>';
    }

    return [
      xAxisFormatter(firstParam.axisValue),
      params
        .map(({ marker, seriesName: id, data }) =>
          [marker, idToSeriesMap[id].title, `<b>${data?.v}</b>`].join(' '),
        )
        .join('<br>'),
    ].join('<br>');
  };
};

export function getTooltipEchartsConfig(
  idToSeriesMap: IDToCombinedSeriesMap,
  timeStampFormat: ChartXAxisTimeStampFormat,
  intl: IntlShape,
) {
  const tooltipFormatter = getTooltipFormatter(idToSeriesMap, timeStampFormat, intl);

  return {
    show: true,
    trigger: 'axis',
    order: 'seriesDesc',
    formatter: tooltipFormatter,
  };
}
