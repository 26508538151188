import React from 'react';
import { Button } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';

import { FEEDBACK_EMAIL, FEEDBACK_SUBJECT, FEEDBACK_BODY } from './send-feedback.definitions';

function SendFeedback() {
  const logger = useLogger();

  const handleOnClick = () => {
    logger.trackEvent(TrackedEvent.SendFeedback);
  };

  return (
    <a
      href={`mailto:${FEEDBACK_EMAIL}?subject=${FEEDBACK_SUBJECT}&body=${FEEDBACK_BODY}`}
      className="grid"
    >
      <Button kind="secondary" mood="neutral" onClick={handleOnClick}>
        <FormattedMessage
          description="Send Feedback Component: Button label for button that collects feedback"
          defaultMessage="Send Feedback"
          id="h3bTr3"
        />
      </Button>
    </a>
  );
}

export default SendFeedback;
