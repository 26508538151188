import React, { FunctionComponentElement } from 'react';

import ProcessRecordList from '../../../shared/components/process-record-list/process-record-list';
import useProcessRecordList from '../../../shared/common/hooks/use-process-record-list';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import { ProcessRecordsProps } from './process-records.definitions';

function ProcessRecords({
  selectedRecordId,
  onSelect,
}: ProcessRecordsProps): FunctionComponentElement<ProcessRecordsProps> {
  const logger = useLogger();

  const { data: processRecords, error, isError, isLoading } = useProcessRecordList();

  const handleSearch = () => {
    logger.trackEvent(TrackedEvent.SearchForProcessRecords);
  };

  return (
    <ProcessRecordList
      isActionBarDisabled
      processRecords={processRecords}
      selectedItems={selectedRecordId ? [selectedRecordId] : []}
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
      isLink={false}
      onClick={onSelect}
      isClickable
      onSearch={handleSearch}
    />
  );
}

export default ProcessRecords;
