import React from 'react';

import { CombinedChart, SplitChart } from '../../../../../shared/charts';
import PlaceholderChart from '../../../../../shared/charts/placeholder-chart';

import { ProcessRecordVisualizationChartSelectionProps } from './process-record-visualization-chart-selection.definitions';

function ProcessRecordVisualizationChartSelection({
  chartType,
  data,
  dataCacheBustKey = '',
  startTime,
  stopTime,
  markLines,
  timeStampFormat,
  defaultYAxisRange,
  onYAxisRangeChange,
  groups,
  isHorizontalZoomSynced,
  isVerticalZoomSynced,
  onZoom,
}: ProcessRecordVisualizationChartSelectionProps) {
  // if no data track types are selected show the placeholder image
  if (data.length === 0) {
    return <PlaceholderChart />;
  }

  if (chartType === 'combined') {
    return (
      <CombinedChart
        data={data}
        dataCacheBustKey={dataCacheBustKey}
        groups={groups}
        markLines={markLines}
        startTime={startTime}
        stopTime={stopTime}
        timeStampFormat={timeStampFormat}
        defaultYAxisRange={defaultYAxisRange}
        onYAxisRangeChange={onYAxisRangeChange}
        onZoom={onZoom}
      />
    );
  }

  if (chartType === 'split') {
    return (
      <SplitChart
        data={data}
        dataCacheBustKey={dataCacheBustKey}
        groups={groups}
        markLines={markLines}
        startTime={startTime}
        stopTime={stopTime}
        timeStampFormat={timeStampFormat}
        defaultYAxisRange={defaultYAxisRange}
        onYAxisRangeChange={onYAxisRangeChange}
        isHorizontalZoomSynced={isHorizontalZoomSynced}
        isVerticalZoomSynced={isVerticalZoomSynced}
        onZoom={onZoom}
      />
    );
  }

  return null;
}

export default ProcessRecordVisualizationChartSelection;
