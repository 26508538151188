import React, { useState } from 'react';
import { CheckmarkIcon, CloseIcon, Modal } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import TrackedEvent from '../../../common/tracked-event';
import useLogger from '../../../common/hooks/use-logger/use-logger';
import YAxisRangeInputs from '../y-axis-range-inputs';
import { SeriesName } from '../time-series-chart.definitions';
import { YAxisRange } from '../y-axis-range-inputs/y-axis-range-inputs.validation';

import { AxisDetailModalProps } from './axis-detail-modal.definitions';
import { getSeriesLabel, getIsRangesValid } from './axis-detail-modal.helpers';

function AxisDetailModal({
  open,
  onOpenChange,
  ranges,
  onRangeChange,
  series,
}: AxisDetailModalProps) {
  const intl = useIntl();
  const logger = useLogger();

  // range of the viewable and zoom-able area on the y axis of the chart
  // a copy of the range is kept so that the previous state can be restored if the user clicks on cancel
  // changes are only notified to the parent element on save
  const [draftYAxisRanges, setDraftYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(ranges);

  // validation state of each y-axis range
  const [validRanges, setValidRanges] = useState<Record<SeriesName, boolean>>({});

  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setDraftYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  const onSave = () => {
    onRangeChange?.(draftYAxisRanges);
    onOpenChange(!open);
    logger.trackEvent(TrackedEvent.SetYAxisRange);
  };

  const updateValidRanges = (seriesName: SeriesName, isValid: boolean) => {
    setValidRanges({ ...validRanges, [seriesName]: isValid });
  };

  const title = intl.formatMessage({
    defaultMessage: 'Set Y-Axis Ranges',
    id: 'tHcVY5',
    description: 'Set y-Axis ranges modal title',
  });

  const isRangesValid = getIsRangesValid(validRanges);

  return (
    <Modal size="xs" title={title} open={open} onOpenChange={onOpenChange}>
      <Modal.Content className="flex flex-col items-start">
        {Object.keys(series).map((seriesName) => (
          <YAxisRangeInputs
            labelPrefix={seriesName}
            labelSuffix={getSeriesLabel(series[seriesName])}
            range={ranges?.get(seriesName)}
            onRangeChange={handleYAxisRangeChange}
            className="mb-3 grid w-full grid-cols-2 flex-row flex-wrap gap-x-2"
            updateValidRanges={updateValidRanges}
            key={seriesName}
          />
        ))}
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button leadIcon={<CloseIcon />}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="SJzycY"
              description="Axis Detail Modal Cancel Button"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          onClick={onSave}
          variant="highlight"
          leadIcon={<CheckmarkIcon />}
          disabled={isRangesValid === false}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            id="SoZUM2"
            description="Axis Detail Modal Confirm Button"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default AxisDetailModal;
