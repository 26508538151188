import React from 'react';
import cn from 'classnames';
import { CellContext } from '@tanstack/react-table';

import { CELL_ERROR_STYLES } from '../editable-data-table.definitions';

function TextCell<TData>({
  getValue,
  row: { index },
  column: {
    id,
    columnDef: { meta },
  },
  table,
}: CellContext<TData, unknown>) {
  const isDisabled = meta?.disabled?.includes(index);
  const isError = meta?.error?.includes(index);
  const type = meta?.type || 'text';
  const initialValue = getValue();

  // When the input is blurred, we'll call our table meta's updateData function
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const newValue = e.currentTarget.value;
    table.options.meta?.updateData(index, id, newValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    table.options.meta?.updateData(index, id, newValue);
  };

  // render value if cell is not editable
  if (isDisabled) {
    return (
      <div
        data-testid="editable-table-text-field-readonly"
        className={`flex h-full items-center px-4 py-2 text-secondary ${cn({
          'justify-end': type === 'number',
          [CELL_ERROR_STYLES]: isError,
        })}`}
      >
        {initialValue as string}
      </div>
    );
  }

  return (
    <input
      className={`h-full w-full rounded-none bg-transparent px-4 py-2 text-right hover:bg-table-hover focus:bg-white focus:outline focus:outline-1 focus:outline-blue-500 ${cn(
        {
          [CELL_ERROR_STYLES]: isError,
        },
      )}`}
      data-testid="editable-table-text-field"
      name={id}
      type={type}
      key={table.getRowCount()} // reset default value when a row is deleted
      defaultValue={(initialValue as string) ?? ''}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}

export default TextCell;
