import { GroupName } from '../../../../shared/charts/chart-groups/chart-groups.definitions';
import { getDataTrackDefaultYAxisRange } from '../../../../shared/charts/chart-y-axis/chart-y-axis';
import { Series, SeriesName } from '../../../../shared/components/time-series-chart';
import { YAxisRange } from '../../setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

import {
  EMPTY_GROUP_DEFAULT_Y_AXIS_MAX,
  EMPTY_GROUP_DEFAULT_Y_AXIS_MIN,
} from './setup-time-series-chart.definitions';

/**
 * calculate default values for the yAxisRange.
 * attempts to get the values from the local storage.
 * if an entry wasn't available in the local storage for the groupName, looks for the data track type 'PV'.
 * calculates min and max of all of the groups series as a fallback.
 * @param seriesGroups
 * @param storageYAxis
 */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function getYAxisRanges(
  seriesGroups: Record<GroupName, Pick<Series, 'dataPoints' | 'dataTrackType'>[]>,
  storageYAxis: Record<SeriesName, YAxisRange> | null,
): Map<GroupName, YAxisRange> {
  const entries = Object.entries(seriesGroups).map<[GroupName, YAxisRange]>(
    ([groupName, series]) => {
      const storageOverride = storageYAxis?.[groupName];

      if (storageOverride) {
        return [groupName, storageOverride];
      }

      // prioritize PV over SP for thw entire group
      const maybePVSeries = series.find((s) => s.dataTrackType.endsWith('.PV'));
      if (maybePVSeries) {
        const storageOverridePV = storageYAxis?.[maybePVSeries.dataTrackType];
        if (storageOverridePV) {
          return [groupName, storageOverridePV];
        }
      }

      // prioritize SP as fallback
      const maybeSPSeries = series.find((s) => s.dataTrackType.endsWith('.SP'));
      if (maybeSPSeries) {
        const storageOverrideSP = storageYAxis?.[maybeSPSeries.dataTrackType];
        if (storageOverrideSP) {
          return [groupName, storageOverrideSP];
        }
      }

      const individualBounds = series.map((s) => getDataTrackDefaultYAxisRange(s.dataPoints));
      const firstBound = individualBounds.at(0);

      const maxOfMaxes = individualBounds.reduce(
        (acc, cur) => Math.max(acc, cur.max ?? acc),
        firstBound?.max ?? EMPTY_GROUP_DEFAULT_Y_AXIS_MAX,
      );

      const minOfMins = individualBounds.reduce(
        (acc, cur) => Math.min(acc, cur.min ?? acc),
        firstBound?.min ?? EMPTY_GROUP_DEFAULT_Y_AXIS_MIN,
      );

      return [groupName, { max: maxOfMaxes, min: minOfMins }];
    },
  );

  return new Map(entries);
}
