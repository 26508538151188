/**
 * Finds the maximum value in an array of numbers.
 *
 * This function is necessary because using the spread operator (`Math.max(...array)`)
 * can cause crashes or performance issues in Chrome when applied to large arrays.
 * By using `Array.prototype.reduce`, we avoid these limitations and ensure
 * better performance and stability.
 *
 * @param {number[]} array - The array of numbers from which to find the maximum value.
 * @returns {number} The maximum value in the array.
 *
 * @example
 * ```ts
 * const numbers = [5, 3, 8, 1, 2];
 * max(numbers); // 8
 *
 * max([]); // 0
 * ```
 */
function max(array: number[]): number {
  if (array.length === 0) {
    return 0;
  }

  return array.reduce((acc, cur) => Math.max(acc, cur));
}

export default max;
