import { Select } from '@biss/react-horizon-web';
import React, { FunctionComponentElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DataTrackTimeAlignment from '../../scenes/setup-details/setup-details.definitions';

import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import { ProcessRecordAlignmentProps } from './process-record-alignment.definitions';

function ProcessRecordAlignment({
  onChangeAlignment,
  defaultValue,
  defaultOpen = false,
  disabled,
}: ProcessRecordAlignmentProps): FunctionComponentElement<ProcessRecordAlignmentProps> {
  const logger = useLogger();
  const intl = useIntl();

  const startTimeOptionValue = DataTrackTimeAlignment.RelativeToStartTime.toString();
  const inoculationTimeOptionValue = DataTrackTimeAlignment.RelativeToInoculationTime.toString();

  const handleAlignmentOption = (value: string) => {
    if (value === startTimeOptionValue) {
      logger.trackEvent(TrackedEvent.AlignReferenceCurveToStartTime);
    }

    if (value === inoculationTimeOptionValue) {
      logger.trackEvent(TrackedEvent.AlignReferenceCurveToInoculationTime);
    }

    onChangeAlignment(Number(value));
  };

  return (
    <div className="mt-3">
      <Select
        label={intl.formatMessage({
          defaultMessage: 'Reference Aligned by',
          id: 'dYQ4av',
          description: 'Reference alignment label.',
        })}
        labelPlacement="top"
        className="w-48"
        data-testid="alignment-combobox"
        placeholder="Select alignment option"
        onValueChange={handleAlignmentOption}
        value={defaultValue}
        defaultOpen={defaultOpen}
        disabled={disabled}
      >
        <Select.Item value={startTimeOptionValue}>
          <FormattedMessage
            description="Button to align data tracks relative to start time"
            defaultMessage="Start Time"
            id="Eoo0VQ"
          />
        </Select.Item>
        <Select.Item value={inoculationTimeOptionValue}>
          <FormattedMessage
            description="Button to align data tracks relative to inoculation time"
            defaultMessage="Inoculation Time"
            id="fFsK+l"
          />
        </Select.Item>
      </Select>
    </div>
  );
}

export default ProcessRecordAlignment;
