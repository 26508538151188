import { HORIZON_COLORS } from '@biss/react-horizon-web';

import { DEFAULT_FRACTIONAL_DIGITS } from '../../../common/types/data-track';
import {
  Series,
  SeriesLegend,
  SeriesMarkLine,
  markLineColor,
  markLineInoculation,
  markLineLastUpdate,
} from '../../../../shared/components/time-series-chart/time-series-chart.definitions';
import {
  isSpOrPvType,
  SP,
  splitDataTrackType,
  getDataTrackColorName,
  formatDataPoints,
} from '../setup-details.helpers';
import DataTrackTimeAlignment from '../setup-details.definitions';
import {
  StructuredProcessRecordObject,
  DataTrack as DataTrackProcessRecord,
} from '../../../../shared/common/types/process-record';
import { getDataTrackColor } from '../../setup-overview/setup-overview.helpers';
import { ProcessValue } from '../process-values/process-values.definitions';
import {
  DataTrackType,
  DataTrackFromTimestampItem,
  DataTrack,
  DataTrackId,
} from '../../../../shared/common/types/setup/data-track';
import { ChartGroupDefinition } from '../../../../shared/charts/chart-groups/chart-groups.definitions';
import groupBy from '../../../../shared/common/utils/group-by/group-by';
import {
  SplitSeries,
  SplitSeriesWithMetadata,
} from '../../../../shared/charts/split-chart/split-chart.definitions';

export const REFERENCE_CURVE_COLOR_KEY = 'LIGHT';
export const REFERENCE_CURVE_LINE_WIDTH = 4;
export const REFERENCE_CURVE_MARK_LINE_COLOR = HORIZON_COLORS.gray.DEFAULT;
export const REFERENCE_CURVE_MARK_LINE_INOCULATION = 'Inoculation Reference';
export const REFERENCE_CURVE_POSTFIX = '.ref';
export const DOTTED_LINE = 'dotted';
export const SET_POINT = 'setPoint';
export const PROCESS_VALUE = 'processValue';

export function getFailedDataTracks(
  dataTracks: DataTrack[],
  requestedDataTrackIds: string[] = [],
): DataTrackFromTimestampItem[] {
  return requestedDataTrackIds
    .filter((id) => !dataTracks.map((item) => item.dataTrackId).includes(id))
    .map((id) => ({
      id,
    }));
}

export function createSeriesItem(dataTrack: DataTrack, color: string, type: string): Series {
  return {
    ...dataTrack,
    dataPoints: formatDataPoints(dataTrack.dataPoints, dataTrack.fractionalDigits),
    color: getDataTrackColor(color),
    // add line type 'dotted' to replace default line type 'line' if type is SP
    ...(type === SP && { lineType: DOTTED_LINE }),
  };
}

export function createReferenceSeriesItem(
  referenceCurveDataTrack: DataTrackProcessRecord,
  color: string,
  type: string,
  timeDifference: number,
): Series {
  return {
    ...referenceCurveDataTrack,
    color: getDataTrackColor(color, REFERENCE_CURVE_COLOR_KEY),
    width: REFERENCE_CURVE_LINE_WIDTH,
    dataPoints: formatDataPoints(
      referenceCurveDataTrack.dataPoints,
      DEFAULT_FRACTIONAL_DIGITS,
      timeDifference,
    ),
    // add line type 'dotted' to replace default line type 'line' if type is SP
    ...(type === SP && { lineType: DOTTED_LINE }),
  };
}

/*
  creates series for the TimeSeriesChart with a reference curve if exists
*/
export function getSeries(
  dataTracks: DataTrack[],
  selectedDataTracks: Record<DataTrackType, string>,
  timeDifference: number,
  referenceCurveDataTracks?: DataTrackProcessRecord[],
): Record<string, Series[]> {
  return Object.keys(selectedDataTracks).reduce(
    (acc: Record<string, Series[]>, key: DataTrackType) => {
      // find the selected data track data
      const dataTrack = dataTracks.find((item) => item.dataTrackType === key);
      if (!dataTrack) {
        return acc;
      }
      const [track, type] = splitDataTrackType(dataTrack.dataTrackType);

      // if dataTrackType is 'PV' or 'SP' use first part of the dataTrackType as series name
      const seriesName = isSpOrPvType(type) ? track : dataTrack.dataTrackType;

      const seriesItem = createSeriesItem(
        dataTrack,
        selectedDataTracks[dataTrack.dataTrackType],
        type,
      );

      // if series does not exist add new series else push to existing one
      if (!acc[seriesName]) {
        acc[seriesName] = [seriesItem];
      } else {
        acc[seriesName].push(seriesItem);
      }

      // find data track with the same dataTrackType in reference process record
      const referenceCurveDataTrack = referenceCurveDataTracks?.find(
        (item) => item.dataTrackType === dataTrack.dataTrackType,
      );

      // add reference process record data track to the series as first item if it exist
      if (referenceCurveDataTrack) {
        const seriesItemReference = createReferenceSeriesItem(
          referenceCurveDataTrack,
          selectedDataTracks[dataTrack.dataTrackType],
          type,
          timeDifference,
        );
        acc[seriesName].unshift(seriesItemReference);
      }
      return acc;
    },
    {},
  );
}

export function getMarkLines(
  timeAlignment: DataTrackTimeAlignment,
  timeDifference: number,
  inoculationTimestamp?: Date,
  referenceCurveInoculationTimestamp?: Date,
  isDisconnected = false,
  lastTimestamp?: number,
  stopTimestamp?: Date,
): SeriesMarkLine[] {
  const seriesMarkLines: SeriesMarkLine[] = [];
  if (
    referenceCurveInoculationTimestamp &&
    timeAlignment === DataTrackTimeAlignment.RelativeToStartTime
  ) {
    seriesMarkLines.push({
      name: REFERENCE_CURVE_MARK_LINE_INOCULATION,
      timestamp: referenceCurveInoculationTimestamp.getTime() + timeDifference,
      color: REFERENCE_CURVE_MARK_LINE_COLOR,
    });
  }
  if (inoculationTimestamp) {
    seriesMarkLines.push({
      name: markLineInoculation,
      timestamp: inoculationTimestamp.getTime(),
      color: markLineColor,
    });
  }
  if (isDisconnected && lastTimestamp && !stopTimestamp) {
    seriesMarkLines.push({
      name: markLineLastUpdate,
      timestamp: lastTimestamp,
      color: markLineColor,
    });
  }
  return seriesMarkLines;
}

export function getTimeDifference(
  timeAlignment: DataTrackTimeAlignment,
  startTimestamp?: Date,
  inoculationTimestamp?: Date,
  referenceCurve?: StructuredProcessRecordObject,
): number {
  if (!referenceCurve || !startTimestamp) {
    return 0;
  }
  const isInoculation = timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime;
  const timestamp = isInoculation ? inoculationTimestamp : startTimestamp;

  const referenceCurveTimestamp = isInoculation
    ? referenceCurve.inoculationTimestamp
    : referenceCurve.startTimestamp;

  return timestamp && referenceCurveTimestamp
    ? timestamp.getTime() - referenceCurveTimestamp.getTime()
    : 0;
}

export function getProcessValues(
  dataTracks: DataTrack[],
  selectedDataTracks: Record<DataTrackType, string>,
  isFinished: boolean,
): Record<string, ProcessValue> | null {
  if (!dataTracks.length || isFinished) {
    return null;
  }
  return dataTracks.reduce((acc: Record<string, ProcessValue>, dataTrack: DataTrack) => {
    const [track, type] = splitDataTrackType(dataTrack.dataTrackType);

    // if dataTrackType is 'PV' or 'SP' use first part of the dataTrackType as process name
    const trackType = isSpOrPvType(type) ? track : dataTrack.dataTrackType;
    const colorName = getDataTrackColorName(dataTrack.dataTrackType, selectedDataTracks);
    const dataTrackItem = {
      value: dataTrack.dataPoints.length
        ? dataTrack.dataPoints[dataTrack.dataPoints.length - 1].v
        : undefined,
      fractionalDigits: dataTrack.fractionalDigits,
      engineeringUnit: dataTrack.engineeringUnit,
    };

    // if dataTrackType is 'SP' data track key should be SET_POINT else PROCESS_VALUE
    const valueType = type === SP ? SET_POINT : PROCESS_VALUE;

    // if process value does not exist add a new one with correct color else push to existing one
    if (!acc[trackType]) {
      acc[trackType] = {
        color: getDataTrackColor(colorName),
        [valueType]: dataTrackItem,
      };
    } else {
      acc[trackType] = {
        ...acc[trackType],
        [valueType]: dataTrackItem,
      };
    }
    return acc;
  }, {});
}

export function getSeriesLegend(
  dataTracks: DataTrack[],
  selectedDataTracks: Record<DataTrackType, string>,
): Record<DataTrackType, SeriesLegend> {
  return Object.keys(selectedDataTracks).reduce(
    (acc: Record<DataTrackType, SeriesLegend>, type: DataTrackType) => {
      const dataTrack = dataTracks.find((item) => item.dataTrackType === type);
      if (!dataTrack) {
        return acc;
      }
      acc[type] = {
        engineeringUnit: dataTrack.engineeringUnit,
        color: getDataTrackColor(selectedDataTracks[type]),
      };
      return acc;
    },
    {},
  );
}

export const yAxisFormatter = (
  value: number,
  fractionalDigits: number = DEFAULT_FRACTIONAL_DIGITS,
) => parseFloat(value.toFixed(fractionalDigits));

export function getDataTracksLastTimestamps(
  dataTracks: DataTrack[],
  requestedDataTrackIds: string[] = [],
): DataTrackFromTimestampItem[] {
  // add failed data track ids to recover the data tracks
  const failedDataTracks = getFailedDataTracks(dataTracks, requestedDataTrackIds);

  const lastTimestamps = dataTracks.map((track: DataTrack) => {
    const lastDataPoint = track.dataPoints.length
      ? track.dataPoints[track.dataPoints.length - 1]
      : undefined;
    const lastDataPointTimeStamp = lastDataPoint?.ts;

    // the timestamp in the database has greater precision
    // to avoid the duplicates request data points after the last timestamp plus 1 milliseconds
    const timestamp = lastDataPointTimeStamp
      ? new Date(lastDataPointTimeStamp + 1).toISOString()
      : undefined;
    return { id: track.dataTrackId, ...(timestamp && { timestamp }) };
  });
  return [...lastTimestamps, ...failedDataTracks];
}

export function isGapRecovery(gapRecoveryTimestamp?: string | null) {
  return !!gapRecoveryTimestamp;
}

export function isHideLoader(isLoadingSetup: boolean, dataTrackIds?: DataTrackId[]): boolean {
  return !!dataTrackIds && !dataTrackIds.length && isLoadingSetup;
}

export const generateSeriesId = (title: string, length: number, id: string) =>
  [title, length, id].join(', ');

// functions to create series for new split and combined charts
export function createSeriesDataItem(dataTrack: DataTrack, colorName: string): SplitSeries {
  const isSp = dataTrack.dataTrackType.endsWith(`.${SP}`);
  const title = dataTrack.dataTrackType;

  return {
    title,
    id: generateSeriesId(title, dataTrack.dataPoints.length, dataTrack.dataTrackId),
    yAxisLabel: dataTrack.engineeringUnit,
    dataPoints: formatDataPoints(dataTrack.dataPoints, dataTrack.fractionalDigits),
    color: getDataTrackColor(colorName),
    // add line type 'dotted' to replace default line type 'line' if type is SP
    lineType: isSp ? DOTTED_LINE : undefined,
  };
}

export function createSeriesDataReferenceItem(
  dataTrack: DataTrack,
  colorName: string,
  timeDifference: number,
): SplitSeries {
  const isSp = dataTrack.dataTrackType.endsWith(`.${SP}`);
  const title = `${dataTrack.dataTrackType}.ref`;

  return {
    title,
    id: generateSeriesId(title, dataTrack.dataPoints.length, dataTrack.dataTrackId),
    yAxisLabel: dataTrack.engineeringUnit,
    dataPoints: formatDataPoints(dataTrack.dataPoints, DEFAULT_FRACTIONAL_DIGITS, timeDifference),
    color: getDataTrackColor(colorName, REFERENCE_CURVE_COLOR_KEY),
    // add line type 'dotted' to replace default line type 'line' if type is SP
    lineType: isSp ? DOTTED_LINE : undefined,
    lineWidth: REFERENCE_CURVE_LINE_WIDTH,
  };
}

export function getSeriesWithMetaData(
  selectedDataTracks: Record<DataTrackType, string>,
  selfDataTracks: DataTrack[],
  referenceDataTracks: DataTrack[],
  timeDifference: number,
): SplitSeriesWithMetadata[] {
  return (
    Object.entries(selectedDataTracks)
      .flatMap(([selectedType, color]) => {
        const maybeDataTrack = selfDataTracks.find((dt) => dt.dataTrackType === selectedType);

        if (maybeDataTrack === undefined) {
          return [];
        }

        const selfSeriesWithMetaData = Object.assign(
          // series
          createSeriesDataItem(maybeDataTrack, color),
          // metadata
          {
            // used to group this series
            dataTrackType: maybeDataTrack.dataTrackType,
          },
        );

        const maybeReferenceDataTrack = referenceDataTracks.find(
          (dt) => dt.dataTrackType === selectedType,
        );

        if (maybeReferenceDataTrack === undefined) {
          return [selfSeriesWithMetaData];
        }

        const referenceSeriesWithMetadata = Object.assign(
          // reference series
          createSeriesDataReferenceItem(maybeReferenceDataTrack, color, timeDifference),
          // metadata
          {
            // used to group this series
            dataTrackType: maybeReferenceDataTrack.dataTrackType,
          },
        );

        return [referenceSeriesWithMetadata, selfSeriesWithMetaData];
      })
      // order reference curves below other data tracks so that they don't overlap and cover actual data
      .sort((series) => (series.title.endsWith(REFERENCE_CURVE_POSTFIX) ? -1 : 1))
  );
}

export function getGroups(
  selectedDataTrackTypes: DataTrackType[],
  seriesWithMetadata: SplitSeriesWithMetadata[],
): ChartGroupDefinition {
  // decide group names and map them to the data track types that should be in that group
  const groupToTypeMap = groupBy(selectedDataTrackTypes, (selectedType) => {
    // if dataTrackType is 'PV' or 'SP' use first part of the dataTrackType as group name
    const [track, type] = splitDataTrackType(selectedType);
    return isSpOrPvType(type) ? track : selectedType;
  });

  // convert the data track types stored in the map to series and then to ids
  return Object.fromEntries(
    Object.entries(groupToTypeMap).map(([groupName, dataTrackTypes]) => [
      groupName,
      seriesWithMetadata.filter((s) => dataTrackTypes?.includes(s.dataTrackType)).map((s) => s.id),
    ]),
  );
}
