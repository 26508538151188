import React from 'react';
import { Button, PlusIcon } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { MetadataForm } from '../metadata-form';

import { ModalContentProps } from './modal-content.definitions';

function ModalContent({
  dataTracks,
  takenTypes,
  localTypes,
  addDataTrack,
  onChange,
  isDisabled,
}: ModalContentProps) {
  return (
    <div className="max-h-[70vh] overflow-auto">
      <MetadataForm
        dataTracks={dataTracks}
        onChange={onChange}
        isDisabled={isDisabled}
        takenTypes={takenTypes}
        localTypes={localTypes}
      />
      <Button
        data-testid="otherDataTrack-button"
        kind="secondary"
        mood="neutral"
        onClick={addDataTrack}
        disabled={isDisabled}
        leftIcon={<PlusIcon />}
      >
        <FormattedMessage
          defaultMessage="Data Track"
          id="FbPnop"
          description="Create Empty Data Track: Add another data track button"
        />
      </Button>
    </div>
  );
}

export default ModalContent;
