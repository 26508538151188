import React, { useEffect, useRef, useState } from 'react';
import { EChartsType } from 'echarts';

import { ChartXAxisTimeStampFormat } from '../chart-formatters/chart-formatters.definitions';
import DebouncedResizeObserver from '../../utils/debounced-resize-observer';
import { createGroups } from '../chart-groups/chart-groups';
import { getYAxisDescriptors } from '../chart-y-axis';
import { GroupName } from '../chart-groups';
import { DataZoomParam } from '../chart-zoom/chart-zoom.definitions';
import { useKeyboard } from '../../common/hooks/use-keyboard';
import useShouldEnableTouch from '../../components/time-series-chart/use-should-enable-touch';

import { getDefaultYAxisMinMax } from '../chart-y-axis/chart-y-axis';

import { SplitChartProps } from './split-chart.definitions';
import useYAxis from './split-chart-y-axis-setup';
import SplitChartItem from './split-chart-item';
import { synchronizeZoom } from './split-chart.helpers';

function SplitChart({
  data,
  dataCacheBustKey = '',
  groups,
  startTime,
  stopTime,
  timeStampFormat = ChartXAxisTimeStampFormat.Date,
  markLines = [],
  isHorizontalZoomSynced = true,
  isVerticalZoomSynced = false,
  defaultYAxisRange,
  onYAxisRangeChange,
  onZoom,
}: SplitChartProps) {
  if (!data.length) {
    throw new TypeError(
      `The split chart component cannot be called with an empty data array!
      Render a different component (e.g. the placeholder chart) when no data is present.`,
    );
  }

  const chartWrapper = useRef<HTMLDivElement>(null);
  const childCharts = useRef<Record<GroupName, EChartsType>>({});
  const [width, setWidth] = useState<number>(0);

  const keyboard = useKeyboard();
  const shouldEnableTouch = useShouldEnableTouch();

  const groupedSeries = createGroups(data, groups);
  const groupNames = Object.keys(groupedSeries);
  const yAxisDescriptors = getYAxisDescriptors(groupedSeries);

  const { modal, sideToolbox, echartsYAxisConfig, yAxisClickCallback } = useYAxis(
    yAxisDescriptors,
    defaultYAxisRange ??
      getDefaultYAxisMinMax(data.map(({ title, dataPoints }) => [title, dataPoints])),
    onYAxisRangeChange,
  );

  useEffect(() => {
    const resizeObserver = new DebouncedResizeObserver({
      threshold: 20,
      callback: (entries) => {
        const newWidth = entries[0].contentRect.width;
        setWidth(newWidth);
      },
    });

    if (chartWrapper.current) {
      resizeObserver.observe(chartWrapper.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleDataZoom = (e: DataZoomParam, targetGroupName: GroupName) => {
    onZoom?.(e, targetGroupName);

    synchronizeZoom(
      e,
      targetGroupName,
      childCharts.current,
      isHorizontalZoomSynced,
      isVerticalZoomSynced,
    );
  };

  return (
    <div
      data-testid="split-chart"
      className="flex flex-col gap-y-4 md:grid md:grid-cols-[6rem,1fr]"
      style={{
        gridTemplateRows: `repeat(${groupNames.length}, 1fr)`,
      }}
    >
      {modal}
      {sideToolbox}
      <div
        ref={chartWrapper}
        className="col-start-2 col-end-2 row-start-1 row-end-[-1] grid gap-y-4 overflow-hidden"
      >
        {groupNames.map((groupName, index) => (
          <SplitChartItem
            title={groupName}
            key={groupName}
            data={groupedSeries[groupName]}
            dataCacheBustKey={dataCacheBustKey}
            startTime={startTime}
            stopTime={stopTime}
            echartsYAxisConfig={echartsYAxisConfig[index]}
            yAxisClickCallback={yAxisClickCallback}
            timeStampFormat={timeStampFormat}
            markLines={markLines}
            width={width}
            onChartInit={(chart) => {
              childCharts.current[groupName] = chart;
            }}
            onDataZoom={(dataZoomParam) => handleDataZoom(dataZoomParam, groupName)}
            zoomLock={(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held) === false}
          />
        ))}
      </div>
    </div>
  );
}

export default SplitChart;
