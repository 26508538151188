import { ChartGroupDefinition, GroupName, SeriesWithIdAndTitle } from './chart-groups.definitions';

/** creates a map from group names to multiple series names */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function createGroups<SeriesLike extends SeriesWithIdAndTitle>(
  data: SeriesLike[],
  groups: ChartGroupDefinition = {},
): Record<string, SeriesLike[]> {
  const groupedSeriesIds = Object.values(groups).flat();

  const notGroupedSeries = data.filter(
    (series: SeriesLike) => groupedSeriesIds.includes(series.id) === false,
  );

  const multipleMemberGroups: [GroupName, SeriesLike[]][] = Object.entries(groups).map(
    ([groupName, groupIds]) => [
      groupName,
      groupIds.flatMap((groupId) => data.filter((seriesData) => seriesData.id === groupId)),
    ],
  );

  const singleMemberGroups: [GroupName, SeriesLike[]][] = notGroupedSeries.map((series, index) => [
    // the index is added in case of multiple series with identical titles
    `${index + 1} ${series.title}`,
    [series],
  ]);

  return Object.fromEntries(multipleMemberGroups.concat(singleMemberGroups));
}
