import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import useMemberRoutes from '../../members/common/routes/use-member-routes';
import useOrganizationRoutes from '../../organizations/common/routes/use-organization-routes';
import RouteDefinition from '../../../shared/common/routes/routes.definitions';
import { Title } from '../../../shared/components/title';
import Base from '../../../shared/scenes/base';

function useRoutes(): React.ReactElement | null {
  const memberManagementRoutes = useMemberRoutes();
  const organizationManagementRoutes = useOrganizationRoutes();
  return (
    <Route path={RouteDefinition.UserManagement} element={<Base />}>
      <Route
        index
        element={
          <Title text="Redirecting to '/members'">
            <Navigate to="members" />
          </Title>
        }
      />

      {memberManagementRoutes}

      {organizationManagementRoutes}
    </Route>
  );
}

export default useRoutes;
