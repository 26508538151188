import React from 'react';

import { Popover, InfoIcon } from '@biss/react-horizon-web';

import { FormattedMessage } from 'react-intl';

import useLogger from '../../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../common/tracked-event';

import { MiniMenuProps } from './mini-menu.definitions';

function MiniMenu({ debugDropdownOpen = undefined }: MiniMenuProps) {
  const logger = useLogger();

  const handleManualClick = () => {
    logger.trackEvent(TrackedEvent.DownloadManual);
  };

  return (
    <Popover
      open={debugDropdownOpen}
      defaultOpen={debugDropdownOpen}
      data-testid="HelpMenu"
      trigger={
        <div data-testid="HelpMenuButton" className="ml-4">
          <InfoIcon className="h-8 w-8 text-gray-50" />
        </div>
      }
    >
      <Popover.Header title="Help" />
      <Popover.Separator />
      <Popover.Item>
        <a
          href="/assets/documents/bionsight_manual_en.pdf"
          target="_blank"
          onClick={handleManualClick}
        >
          <FormattedMessage
            defaultMessage="Software Manual"
            description="Help Menu: label for 'Software Manual' menu entry"
            id="l78vb9"
          />
        </a>
      </Popover.Item>
    </Popover>
  );
}

export default MiniMenu;
