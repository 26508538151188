import {
  ChartGroupDefinition,
  SeriesId,
} from '../../../../../shared/charts/chart-groups/chart-groups.definitions';
import { ChartView } from '../../../../../shared/charts/chart-toolbox/chart-toolbox.definitions';
import { DataZoomParam } from '../../../../../shared/charts/chart-zoom/chart-zoom.definitions';
import { SplitSeries as SeriesSplitChart } from '../../../../../shared/charts/split-chart';

import { SeriesMarkLine } from '../../../../../shared/components/time-series-chart/time-series-chart.definitions';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';

export type SetupTimeSeriesChartVisualizationProps = {
  startTimestamp?: number;
  stopTimestamp: number;
  seriesMarkLines: SeriesMarkLine[];
  chartView: ChartView;
  seriesData?: SeriesSplitChart[];
  groups: ChartGroupDefinition;
  yAxisRanges?: Map<SeriesId, YAxisRange>;
  setYAxisRanges?: (ranges: Map<SeriesId, YAxisRange>) => void;
  setChartView: (view: ChartView) => void;

  /** Called when any of the charts is zoomed */
  onZoom?: (param: DataZoomParam) => void;
};

export const MAX_GROUP_LENGTH = 8;
