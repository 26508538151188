import React, { useState } from 'react';

import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import AxisDetailModal from '../../../components/time-series-chart/axis-detail-modal';
import { SeriesDescriptor } from '../../../components/time-series-chart/axis-detail-modal/axis-detail-modal.definitions';
import { SeriesName } from '../../chart-groups';
import { CHART_Y_AXIS_ZOOM_SLIDER_ID } from '../../chart-zoom/chart-zoom.definitions';

import YAxisDescriptor from './combined-chart-y-axis-setup.definitions';
import { getYAxisEchartsConfig } from './combined-chart-y-axis-setup.helpers';

export default function useYAxis(
  content: Array<YAxisDescriptor>,
  onResetZoom: (dataZoomId: string) => void,
  yAxisRange: Map<SeriesName, YAxisRange> = new Map(),
  onYAxisRangeChange?: (ranges: Map<SeriesName, YAxisRange>) => void,
) {
  const [modalOpen, setModalOpen] = useState(false);

  const echartsYAxisConfig = getYAxisEchartsConfig(content, yAxisRange);

  const series: Record<SeriesName, Array<SeriesDescriptor>> = Object.fromEntries(
    content.map<[SeriesName, Array<SeriesDescriptor>]>(({ label, name }) => [
      name,
      [{ name, label }],
    ]),
  );

  const yAxisClickCallback = (params: object) => {
    // check if the click came from a y axis label
    if ('yAxisIndex' in params === false || typeof params.yAxisIndex !== 'number') {
      return;
    }

    setModalOpen(true);
  };

  // call y-axis range callback if it has been changed
  const handleRangeChange = (ranges: Map<string, YAxisRange>): void => {
    // notify parent of state update
    onResetZoom(CHART_Y_AXIS_ZOOM_SLIDER_ID);

    onYAxisRangeChange?.(ranges);
  };

  const modal = (
    <AxisDetailModal
      open={modalOpen}
      onOpenChange={setModalOpen}
      onRangeChange={handleRangeChange}
      ranges={yAxisRange}
      series={series}
    />
  );

  return { modal, echartsYAxisConfig, yAxisClickCallback };
}
