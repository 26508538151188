// eslint-disable-next-line import/prefer-default-export -- helper file
export const getDefaultDraftDataPoints = (dataPointsLength: number) =>
  dataPointsLength > 0
    ? undefined
    : [
        {
          isTimeStampDirty: false,
          isValueDirty: false,
          timeStamp: new Date().getTime(),
          value: '',
        },
      ];
