import React from 'react';
import { Button, Select, Icons32 } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';

import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';

import TrackedEvent from '../../../../shared/common/tracked-event';

import { SetupNavigationProps } from './setup-navigation.definitions';
import getNextPreviousSetups from './setup-navigation.helpers';

function SetupNavigation({
  workflowInfo,
  setupInfo,
  setupId,
  defaultOpen,
  onChangeSetup,
}: SetupNavigationProps) {
  const intl = useIntl();
  const logger = useLogger();

  const handleSetupOption = (value?: string) => {
    if (value === undefined) {
      return;
    }

    logger.trackEvent(TrackedEvent.NavigateBetweenUnits);
    onChangeSetup(value);
  };

  const deviceName = workflowInfo?.deviceName || setupInfo?.deviceName;
  const title = workflowInfo?.title || setupInfo?.title;
  const { previousSetup, nextSetup } = getNextPreviousSetups(setupId, workflowInfo);
  return (
    <div className="flex-wrap items-center text-textGray md:flex md:text-xl lg:pb-0 lg:text-2xl">
      <div className="mr-2" data-testid="workflow-title">
        <span className="mr-2 inline-block font-bold">{deviceName}</span>
        <span className="font-medium">{title}</span>
      </div>
      {workflowInfo && workflowInfo.setups.length > 1 ? (
        <div className="flex items-center">
          <Button
            kind="ghost"
            mood="neutral"
            leftIcon={
              <Icons32.ChevronLeftIcon
                title={intl.formatMessage({
                  description: 'Previous button: title for the Previous button',
                  defaultMessage: 'Previous',
                  id: 'ijpigq',
                })}
              />
            }
            onClick={() => handleSetupOption(previousSetup?.setupId)}
            disabled={!previousSetup}
            data-testid="setup-navigation-previous-button"
          />
          <div className="mx-2 min-w-[100px]">
            <Select
              data-testid="setup-navigation-combobox"
              onValueChange={handleSetupOption}
              value={setupId}
              defaultOpen={defaultOpen}
              disabled={workflowInfo.setups.length === 1}
              expand="auto"
            >
              {workflowInfo.setups.map((item) => (
                <Select.Item value={item.setupId} key={item.setupId}>
                  <FormattedMessage
                    description="Setup Navigation Option"
                    defaultMessage="{value}"
                    id="BPiiXM"
                    values={{ value: item.unit }}
                  />
                </Select.Item>
              ))}
            </Select>
          </div>
          <Button
            kind="ghost"
            mood="neutral"
            leftIcon={
              <Icons32.ChevronRightIcon
                title={intl.formatMessage({
                  description: 'Next button: title for the Next button',
                  defaultMessage: 'Next',
                  id: 'nQQq52',
                })}
              />
            }
            onClick={() => handleSetupOption(nextSetup?.setupId)}
            disabled={!nextSetup}
            data-testid="setup-navigation-next-button"
          />
        </div>
      ) : (
        setupInfo?.unit
      )}
    </div>
  );
}

export default SetupNavigation;
