/**
 * Rounds an array of numbers so that all values are divisible by a specified divisor.
 * values can be rounded up or down based on the `roundUp` parameter.
 *
 * @param numbers - the array of numbers to round.
 * @param divisor - the common divisor.
 * @param roundUp - specifies whether to round up (`true`) or down (`false`).
 * @returns a new array of numbers rounded to the closest multiples of the divisor.
 *
 * @example
 *
 * roundToCommonDivisor([23.7, 36.4, 48.1, 53.9, 67.2], 5, false);
 * // returns [20, 35, 45, 50, 65]
 */
function roundToCommonDivisor(numbers: number[], divisor = 5, roundUp = true): number[] {
  return numbers.map((num) => {
    const remainder = num % divisor;

    if (remainder === 0) {
      return num; // already divisible by the divisor
    }

    if (roundUp) {
      // round up to the next multiple
      return num + (divisor - remainder);
    }

    // round down to the previous multiple
    return num - remainder;
  });
}

export default roundToCommonDivisor;
