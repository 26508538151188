import React, { useEffect, useRef, useState } from 'react';
import { init } from 'echarts';
import { useIntl } from 'react-intl';
import { Button, ZoomResetIcon } from '@biss/react-horizon-web';

import { ChartXAxisTimeStampFormat } from '../chart-formatters/chart-formatters.definitions';
import {
  CHART_Y_AXIS_ZOOM_SLIDER_ID,
  DataZoomParam,
  CHART_INITIAL_ZOOM,
} from '../chart-zoom/chart-zoom.definitions';
import DebouncedResizeObserver from '../../utils/debounced-resize-observer';
import { getTooltipEchartsConfig } from '../chart-tooltip';
import { useKeyboard } from '../../common/hooks/use-keyboard';
import useShouldEnableTouch from '../../components/time-series-chart/use-should-enable-touch';
import { getXAxisEchartsConfig } from '../chart-x-axis';
import { getDatasetEchartsConfig } from '../chart-dataset';
import { createGroups } from '../chart-groups';
import { getYAxisDescriptors } from '../chart-y-axis';
import { getDataCacheKey, getMarkLinesCacheKey, getToolboxEchartsConfig } from '../charts.helpers';

import { getDefaultYAxisMinMax } from '../chart-y-axis/chart-y-axis';

import { CombinedChartProps } from './combined-chart.definitions';
import { COMBINED_CHART_TOTAL_HEIGHT } from './combined-chart.config';
import useZoom from './combined-chart-zoom-setup';
import {
  createIdToDataMap,
  getCombinedChartLayout,
  getGridEchartsConfig,
  getLegendEchartsConfig,
  getSeriesEchartsConfig,
} from './combined-chart.helpers';
import useYAxis from './combined-chart-y-axis-setup';

// TODO: (BIOCL-4589): allow user input x axis label formatter
function CombinedChart({
  data,
  dataCacheBustKey = '',
  groups,
  startTime,
  stopTime,
  timeStampFormat = ChartXAxisTimeStampFormat.Date,
  markLines = [],
  defaultYAxisRange,
  onZoom,
  onYAxisRangeChange,
  /** Used in tests only */
  defaultEcharts,
}: CombinedChartProps) {
  if (data.length === 0) {
    throw new TypeError(
      `The combined chart component cannot be called with an empty data array!
      Render a different component (e.g. the placeholder chart) when no data is present.`,
    );
  }

  const intl = useIntl();
  const keyboard = useKeyboard();
  const shouldEnableTouch = useShouldEnableTouch();

  const root = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState(
    // passed only in tests
    defaultEcharts,
  );

  const groupedSeries = createGroups(data, groups);
  const groupLength = Object.keys(groupedSeries).length;
  const yAxisDescriptors = getYAxisDescriptors(groupedSeries);

  const seriesIdToSeriesMap = createIdToDataMap(data);

  const { onZoomCallback, onResetZoom, getChartZoomConfig } = useZoom(groupLength, timeStampFormat);

  const handleResetZoom = () => {
    onResetZoom();
    chart?.setOption(
      {
        dataZoom: [CHART_INITIAL_ZOOM, CHART_INITIAL_ZOOM, CHART_INITIAL_ZOOM],
      },
      { lazyUpdate: true },
    );
  };

  const handleResetYAxisZoom = () => {
    onResetZoom(CHART_Y_AXIS_ZOOM_SLIDER_ID);
    chart?.setOption(
      {
        dataZoom: [{}, {}, CHART_INITIAL_ZOOM],
      },
      { lazyUpdate: true },
    );
  };

  const { modal, echartsYAxisConfig, yAxisClickCallback } = useYAxis(
    yAxisDescriptors,
    handleResetYAxisZoom,
    defaultYAxisRange ??
      getDefaultYAxisMinMax(data.map(({ title, dataPoints }) => [title, dataPoints])),
    onYAxisRangeChange,
  );

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   *
   *  The key should contain of unique features of the data prop and should therefore only
   *    trigger dependent code when the data actually changes.
   *  See https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
   */
  const dataCacheKey = getDataCacheKey(dataCacheBustKey, data);

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const markLinesCacheKey = getMarkLinesCacheKey(markLines);

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const yAxisRangeCacheKey = echartsYAxisConfig
    .map((entry) => `${entry.name}${entry.name}${entry.min}${entry.max}`)
    .join()
    .concat(echartsYAxisConfig.length.toString());

  const initialize = () => {
    // this will attempt to initialize the chart twice in strict mode but fail gracefully with a warning
    const initializedChart =
      defaultEcharts ??
      init(root.current, 'horizon-web', {
        height: COMBINED_CHART_TOTAL_HEIGHT,
      });

    const dataset = getDatasetEchartsConfig(data);
    const series = getSeriesEchartsConfig(groupedSeries, markLines, dataset);

    // set initial chart options
    initializedChart.setOption({
      animation: false,
      toolbox: getToolboxEchartsConfig(),
      xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
      legend: getLegendEchartsConfig(data),
      grid: getGridEchartsConfig(groupLength),
      tooltip: getTooltipEchartsConfig(seriesIdToSeriesMap, timeStampFormat, intl),
      yAxis: echartsYAxisConfig,
      dataZoom: getChartZoomConfig(),
      series,
      dataset,
    });

    // add event listeners
    initializedChart.on('datazoom', (e: unknown) => {
      const params = e as DataZoomParam;

      onZoom?.(params);

      // update zoom
      onZoomCallback(params);
    });

    initializedChart.on('click', yAxisClickCallback);

    // resize the chart when the ref size changes (e.g. when the side panel is closed/opened)
    const resizeObserver = new DebouncedResizeObserver({
      threshold: 20,
      callback: (entries) => {
        const newWidth = entries.at(-1)?.contentRect.width;
        if (newWidth === undefined || newWidth === 0 || initializedChart.isDisposed()) {
          return;
        }
        initializedChart.resize({ width: newWidth });
      },
    });

    if (root.current) {
      resizeObserver.observe(root.current);
    }

    setChart(initializedChart);

    return () => {
      resizeObserver.disconnect();

      initializedChart.off('datazoom');
      initializedChart.off('click');
      initializedChart.dispose();
    };
  };

  /** properties dependent on {@link dataCacheKey} */
  useEffect(
    // initialize a new chart when data changes because echarts internally caches settings
    initialize,
    [dataCacheKey],
  );

  /** properties dependent on {@link startTime} and {@link stopTime} */
  useEffect(() => {
    chart?.setOption(
      {
        xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
        dataZoom: getChartZoomConfig(),
      },
      { lazyUpdate: true },
    );
  }, [startTime, stopTime]);

  /** properties dependent on {@link timeStampFormat} */
  useEffect(() => {
    chart?.setOption(
      {
        xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
        tooltip: getTooltipEchartsConfig(seriesIdToSeriesMap, timeStampFormat, intl),
        dataZoom: getChartZoomConfig(),
      },
      { lazyUpdate: true },
    );
  }, [timeStampFormat]);

  /** properties dependant on {@link markLines} */
  useEffect(() => {
    const dataset = getDatasetEchartsConfig(data);
    chart?.setOption(
      {
        series: getSeriesEchartsConfig(groupedSeries, markLines, dataset),
      },
      { lazyUpdate: true },
    );
  }, [markLinesCacheKey]);

  /** properties dependent on {@link yAxisRangeCacheKey} */
  useEffect(() => {
    chart?.setOption(
      {
        yAxis: echartsYAxisConfig,
      },
      { lazyUpdate: true },
    );
  }, [yAxisRangeCacheKey]);

  /** activate zooming through shortcut keys */
  useEffect(() => {
    const zoomLock = !(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held);

    chart?.setOption(
      {
        dataZoom: [
          {},
          // inside zoom
          { zoomLock },
          {},
        ],
      },
      { lazyUpdate: true },
    );
  }, [keyboard.Control.held, keyboard.Shift.held, shouldEnableTouch]);

  const { cols, rows } = getCombinedChartLayout(groupLength);

  return (
    <>
      {modal}
      <div className="relative w-full">
        <div
          data-testid="combined-chart"
          ref={root}
          style={{
            height: `${COMBINED_CHART_TOTAL_HEIGHT}px`,
          }}
        />
        <div
          data-testid="chart-overlay-layout"
          className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 grid w-full"
          style={{
            gridTemplateRows: rows,
            gridTemplateColumns: cols,
          }}
        >
          {/* intractable elements should explicitly be set to have the "pointer-events-auto" class */}
          <div className="pointer-events-auto col-start-1 row-start-5 flex items-center justify-end p-1">
            <Button
              data-testid="combined-chart-reset-zoom"
              kind="ghost"
              rightIcon={<ZoomResetIcon />}
              onClick={handleResetZoom}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CombinedChart;
