type GroupKey = string | number;

/**
 * a local implementation of {@link Object.groupBy} because the native function lacks support in test environments
 * @param values
 * @param predicate
 */
function groupBy<Data>(values: Data[], predicate: (value: Data) => GroupKey) {
  // using reduce to aggregate values
  return values.reduce(
    (acc, item) => {
      const key = predicate(item);

      // aggregate values based on the keys
      if (acc[key] === undefined) {
        acc[key] = [item];
      } else {
        acc[key] = [...acc[key], item];
      }

      return acc;
    },
    {} as Record<GroupKey, Data[]>,
  );
}

export default groupBy;
