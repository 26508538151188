import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Input } from '@biss/react-horizon-web';
import { useDebounce } from 'usehooks-ts';

import {
  DEFAULT_DEBOUNCE_THRESHOLD,
  DebouncedInputProps,
  DebouncedInputRef,
} from './debounced-input.definitions';

const DebouncedInput = forwardRef<DebouncedInputRef, DebouncedInputProps>(
  (
    {
      initialValue,
      onDebouncedChange: updateValue,
      debounceThreshold = DEFAULT_DEBOUNCE_THRESHOLD,
      ...props
    },
    ref,
  ) => {
    const previousNotifiedValue = useRef<string>(initialValue ?? '');

    const [value, setValue] = useState(initialValue);
    const debouncedValue = useDebounce(value, debounceThreshold);

    const notifyOfValueChange = (val: string) => {
      // do not repeat value notifcation
      if (previousNotifiedValue.current === val) {
        return;
      }

      updateValue?.(val);
      previousNotifiedValue.current = val;
    };

    useEffect(() => notifyOfValueChange(debouncedValue ?? ''), [debouncedValue]);

    useImperativeHandle(ref, () => ({
      setValue(val) {
        setValue(val);
        // immediate notification of change
        notifyOfValueChange(val);
      },
    }));

    return (
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading -- wrapping rhw component
        {...props}
        value={value}
        onChange={(el) => setValue(el.currentTarget.value)}
      />
    );
  },
);
export default DebouncedInput;
