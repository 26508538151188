import { PropsWithChildren } from 'react';

export type ChartToolboxProps = PropsWithChildren<{
  /**
   * Determines whether toggle button should bu shown.
   * defaults to `true`
   */
  showChartViewToggle?: boolean;

  /**
   * Defines chart view.
   * defaults to 'combined'
   */
  chartView?: ChartView;

  /** Disable toolbox buttons. */
  disabled?: boolean;

  /** Callback function to set chart view. */
  setChartView?: (view: ChartView) => void;
}>;

export const CHART_VIEW = ['combined', 'split'] as const;
export type ChartView = (typeof CHART_VIEW)[number];
