import React, { useState } from 'react';

import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import AxisDetailModal from '../../../components/time-series-chart/axis-detail-modal';
import { SeriesDescriptor } from '../../../components/time-series-chart/axis-detail-modal/axis-detail-modal.definitions';
import SideToolbox from '../side-toolbox';
import { SeriesName } from '../../chart-groups';

import YAxisDescriptor from './split-chart-y-axis-setup.definitions';
import { getYAxisEchartsConfig } from './split-chart-y-axis-setup.helpers';

export default function useYAxis(
  content: Array<YAxisDescriptor>,
  yAxisRanges: Map<SeriesName, YAxisRange> = new Map(),
  onYAxisRangeChange?: (ranges: Map<SeriesName, YAxisRange>) => void,
) {
  const [rangeModalOpen, setRangeModalOpen] = useState(false);

  const echartsYAxisConfig = getYAxisEchartsConfig(content, yAxisRanges);

  function yAxisClickCallback(params: object) {
    if ('yAxisIndex' in params === false || typeof params.yAxisIndex !== 'number') {
      return;
    }

    setRangeModalOpen(true);
  }

  const handleToolboxRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    const newMap = new Map(yAxisRanges);
    newMap.set(seriesType, newRange);

    onYAxisRangeChange?.(newMap);
  };

  const handleModalRangeChange = (ranges: Map<SeriesName, YAxisRange>) => {
    onYAxisRangeChange?.(ranges);
  };

  const series: Record<SeriesName, Array<SeriesDescriptor>> = Object.fromEntries(
    content.map<[SeriesName, Array<SeriesDescriptor>]>(({ label, name }) => [
      name,
      [{ name, label }],
    ]),
  );

  const sideToolbox = Object.keys(series).map((item) => (
    <SideToolbox
      key={item}
      onRangeChange={handleToolboxRangeChange}
      range={yAxisRanges.get(item) ?? {}}
      seriesName={item}
    />
  ));

  const modal = (
    <AxisDetailModal
      open={rangeModalOpen}
      onOpenChange={setRangeModalOpen}
      onRangeChange={handleModalRangeChange}
      ranges={yAxisRanges}
      series={series}
    />
  );

  return { modal, sideToolbox, echartsYAxisConfig, yAxisClickCallback };
}
