/**
 * Finds the minimum value in an array of numbers.
 *
 * This function is necessary because using the spread operator (`Math.min(...array)`)
 * can cause crashes or performance issues in Chrome when applied to large arrays.
 * By using `Array.prototype.reduce`, we avoid these limitations and ensure
 * better performance and stability.
 *
 * @param {number[]} array - The array of numbers from which to find the minimum value.
 * @returns {number} The minimum value in the array.
 *
 * @example
 * ```ts
 * const numbers = [5, 3, 8, 1, 2];
 * min(numbers); // 1
 *
 * min([]); // 0
 * ```
 */
function min(array: number[]): number {
  if (array.length === 0) {
    return 0;
  }

  return array.reduce((acc, cur) => Math.min(acc, cur));
}

export default min;
