import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  useAuthContext,
  isAdministrator,
  isEppendorfAdministrator,
  isEppendorfService,
  useIsAuthenticated,
} from '@biss/react-auth-web';
import { NavigationItem } from '@biss/react-horizon-web';

import RouteDefinition from '../../../common/routes/routes.definitions';

import useLogger from '../../../common/hooks/use-logger/use-logger';

import TrackedEvent from '../../../common/tracked-event';

import { NavigationProps, NavigationDefinition } from './navigation.definitions';
import NavigationSkeleton from './navigation.skeleton';

function Navigation({ onNavLinkClick }: NavigationProps) {
  const logger = useLogger();

  const { account } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();

  if (account === null || isAuthenticated === false) {
    return <NavigationSkeleton />;
  }

  const memberManagementEnabled = isAdministrator(account) || isEppendorfAdministrator(account);

  const organizationManagementEnabled = isEppendorfAdministrator(account);

  const analyticsAndMonitoringEnabled =
    isEppendorfAdministrator(account) === false && isEppendorfService(account) === false;

  const links: NavigationDefinition[] = [];

  if (analyticsAndMonitoringEnabled) {
    links.push({
      name: 'Analytics',
      to: `${RouteDefinition.Analytics}/process-records`,
      trackedEvent: TrackedEvent.Analytics,
    });

    links.push({
      name: 'Monitoring',
      to: `${RouteDefinition.Monitoring}/setups`,
      trackedEvent: TrackedEvent.Monitoring,
    });
  }

  if (memberManagementEnabled) {
    links.push({
      name: 'User Management',
      to: `${RouteDefinition.UserManagement}/members`,
      trackedEvent: TrackedEvent.UserManagement,
    });
  }

  if (organizationManagementEnabled) {
    links.push({
      name: 'Organization Management',
      to: `${RouteDefinition.UserManagement}/organizations`,
      trackedEvent: TrackedEvent.OrganizationManagement,
    });
  }

  const handleNavLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    to: string,
    trackedEvent: string,
  ) => {
    logger.trackEvent(trackedEvent);

    onNavLinkClick?.(e, to);
  };

  return links.map(({ to, name, trackedEvent }) => (
    <NavLink key={name} onClick={(e) => handleNavLinkClick(e, to, trackedEvent)} to={to}>
      {({ isActive }) => <NavigationItem isActive={isActive}>{name}</NavigationItem>}
    </NavLink>
  ));
}

export default Navigation;
