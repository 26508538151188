import { DataZoomComponentOption, EChartsType } from 'echarts';
import { ECBasicOption } from 'echarts/types/dist/shared';

import {} from '../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import { GroupName } from '../chart-groups';
import {
  DataZoomParam,
  CHART_X_AXIS_ZOOM_SLIDER_ID,
  CHART_Y_AXIS_ZOOM_SLIDER_ID,
} from '../chart-zoom/chart-zoom.definitions';

/** manually synchronize the zooming and panning of separate charts */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function synchronizeZoom(
  param: DataZoomParam,
  targetGroupName: GroupName,
  groupToCharts: Record<GroupName, EChartsType>,
  isHorizontalZoomSynced: boolean,
  isVerticalZoomSynced: boolean,
) {
  const start = param.start ?? param.batch?.[0].start;
  const end = param.end ?? param.batch?.[0].end;

  if (start === undefined || end === undefined) {
    return;
  }

  // or if there are no ids at all assume horizontal (keyboard shortcuts)
  const isZoomHorizontal = param.dataZoomId?.startsWith(CHART_X_AXIS_ZOOM_SLIDER_ID) ?? true;
  const isZoomVertical = param.dataZoomId?.startsWith(CHART_Y_AXIS_ZOOM_SLIDER_ID) ?? false;

  Object.entries(groupToCharts).forEach(([groupName, chart]) => {
    const currentOption = chart.getOption() as ECBasicOption & {
      dataZoom: DataZoomComponentOption[];
    };

    const option: ECBasicOption = {
      dataZoom: currentOption.dataZoom.map((brush) => {
        if (typeof brush.id !== 'string') {
          return brush;
        }

        if (
          isHorizontalZoomSynced &&
          isZoomHorizontal &&
          brush.id.startsWith(CHART_X_AXIS_ZOOM_SLIDER_ID)
        ) {
          return {
            ...brush,
            start,
            end,
          };
        }

        if (
          isVerticalZoomSynced &&
          isZoomVertical &&
          brush.id.startsWith(CHART_Y_AXIS_ZOOM_SLIDER_ID)
        ) {
          return {
            ...brush,
            start,
            end,
          };
        }

        return brush;
      }),
    };

    // update the zoom settings of all other charts
    if (targetGroupName !== groupName) {
      chart.setOption(option, false, true);
    }
  });
}
