import { useIntl } from 'react-intl';

import { DATA_COLORS } from '@biss/react-horizon-web';

import timeSpan, { Precision } from '../../../../../shared/utils/time-span';
import {
  DataPointObject,
  SeriesMarkLine,
} from '../../../../../shared/components/time-series-chart';
import {
  CategoricalColor,
  DataTrackTimeAlignment,
  MarkedDataTrack,
} from '../process-record-visualization.definitions';
import { calculateRelativeTimestamps } from '../process-record-visualization.helpers';
import { DataTrack, DataTrackType } from '../../../../../shared/common/types/process-record';
import max from '../../../../../shared/common/utils/max';
import min from '../../../../../shared/common/utils/min';
import { ColoredProcessRecord } from '../../../process-record-comparison/process-record-comparison.definitions';
import { UseMultipleAnalyticsDataTracksInput } from '../../../../../shared/common/hooks/use-multiple-analytics-data-tracks';

export function getEarliestDate(dates: Date[]): Date {
  if (dates.length === 0) {
    throw new TypeError('The given dates may not be empty');
  }

  return dates.reduce((pre, cur) => (pre > cur ? cur : pre));
}

export function useFormatXAxisTicks(): (ts: number) => string {
  const intl = useIntl();

  return (ts: number) =>
    intl.formatDate(ts, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function useRelativeXAxisTimestamps(precision: Precision = 'hours'): (ts: number) => string {
  return (ts: number) => timeSpan(ts, precision);
}

export const calculateInoculationTime = (
  timeAlignment: DataTrackTimeAlignment,
  startTimeTimestamp?: number,
  inoculationTime?: number,
): number | undefined => {
  if (!inoculationTime) {
    return undefined;
  }

  if (timeAlignment === DataTrackTimeAlignment.Absolute) {
    return inoculationTime;
  }

  if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
    return 0;
  }

  if (!startTimeTimestamp) {
    return 0;
  }

  return inoculationTime - startTimeTimestamp;
};

export const calculateDataTrackDataPoints = (
  rawDataPoints: DataPointObject[],
  timeAlignment: DataTrackTimeAlignment,
  startTimestamp: Date,
  inoculationTimestamp?: Date,
) => {
  if (timeAlignment === DataTrackTimeAlignment.RelativeToStartTime) {
    return calculateRelativeTimestamps(startTimestamp.getTime(), rawDataPoints);
  }

  if (timeAlignment === DataTrackTimeAlignment.RelativeToInoculationTime) {
    if (inoculationTimestamp === undefined) {
      throw new Error(
        `Expected inoculationTimestamp to be defined when timeAlignment=${DataTrackTimeAlignment.RelativeToInoculationTime}`,
      );
    }

    return calculateRelativeTimestamps(inoculationTimestamp.getTime(), rawDataPoints);
  }

  if (timeAlignment === DataTrackTimeAlignment.Absolute) {
    return rawDataPoints;
  }

  throw new Error(`Unhandled arm. Scenario timeAlignment=${timeAlignment} was not handled.`);
};

export const getDataTracksTimestamps = (dataTracks: Pick<DataTrack, 'dataPoints'>[]) =>
  dataTracks.flatMap((dataTrack) => dataTrack.dataPoints.map((dataPoint) => dataPoint.ts));

/**
 * get the oldest data point out of all the {@link dataTracks data tracks} passed in the input.
 * the oldest data point is the one with the smallest `ts` value.
 * this function assumes the passed in {@link dataTracks data tracks} have their `dataPoints` sorted by `timeStamp`.
 * @param dataTracks
 */
export const calculateOldestDataPointTimestamp = (dataTracks: DataTrack[]) =>
  min(
    dataTracks
      // attempt to get the first data point of every data track
      .flatMap((dataTrack) => dataTrack.dataPoints.at(0)?.ts)
      // filter out empty data tracks
      .filter((maybeTs): maybeTs is number => maybeTs !== undefined),
  );

/**
 * get the youngest data point out of all the {@link dataTracks data tracks} passed in the input.
 * the youngest data point is the one with the greatest `ts` value.
 * this function assumes the passed in {@link dataTracks data tracks} have their `dataPoints` sorted by `timeStamp`.
 * @param dataTracks
 */
export const calculateYoungestDataPointTimestamp = (dataTracks: DataTrack[]) =>
  max(
    dataTracks
      // attempt to get the last data point of every data track
      .flatMap((dataTrack) => dataTrack.dataPoints.at(-1)?.ts)
      // filter out empty data tracks
      .filter((maybeTs): maybeTs is number => maybeTs !== undefined),
  );

/**
 * determines which data tracks need to be fetched from the backend,
 * @param coloredProcessRecords
 * @param selectedDataTrackTypes
 * @returns each data tracks id and its process record id
 */
export const getFetchableDataTracks = (
  coloredProcessRecords: ColoredProcessRecord[],
  selectedDataTrackTypes: string[],
) =>
  coloredProcessRecords.reduce(
    (acc, processRecord) =>
      acc.concat(
        processRecord.dataTracks
          .filter((dt) => selectedDataTrackTypes.includes(dt.dataTrackType))
          .map((dataTrack) => [processRecord.processRecordId, dataTrack.dataTrackId]),
      ),
    [] as UseMultipleAnalyticsDataTracksInput,
  );

/**
 * mark every fetched data track with a reference to its process record
 * colorize data tracks based on the view mode
 * align data track data points relative to the time alignment
 * @param coloredProcessRecords
 * @param fetchedDataTracks
 * @param inSingleView
 * @param getColor
 * @param timeAlignment
 */
export const markDataTracks = (
  coloredProcessRecords: ColoredProcessRecord[],
  fetchedDataTracks: DataTrack[],
  inSingleView: boolean,
  getColor: (dataTrackType: DataTrackType) => CategoricalColor,
  timeAlignment: DataTrackTimeAlignment,
) =>
  coloredProcessRecords.reduce<MarkedDataTrack[]>((acc, processRecord) => {
    const currentProcessRecordDataTrackIds = processRecord.dataTracks.map((qDt) => qDt.dataTrackId);
    const currentProcessRecordFetchedDataTracks: MarkedDataTrack[] = fetchedDataTracks
      // get the current process records fetched data tracks
      .filter((dt) => currentProcessRecordDataTrackIds.includes(dt.dataTrackId))
      .map(
        (dt) =>
          ({
            ...dt,
            // mark data track
            processRecord,
            // colorize data track
            color: inSingleView ? getColor(dt.dataTrackType) : processRecord.color,
            // align data points
            dataPoints: calculateDataTrackDataPoints(
              dt.dataPoints,
              timeAlignment,
              processRecord.startTimestamp,
              processRecord.inoculationTimestamp,
            ),
          }) satisfies MarkedDataTrack,
      );

    return acc.concat(currentProcessRecordFetchedDataTracks);
  }, []);

/**
 * construct the mark lines configuration object to pass to the charts
 * generates a for the inoculation time if it exists
 * @param coloredProcessRecords
 * @param timeAlignment
 * @param inSingleView
 */
export const getMarkLines = (
  coloredProcessRecords: ColoredProcessRecord[],
  timeAlignment: DataTrackTimeAlignment,
  inSingleView: boolean,
) =>
  coloredProcessRecords.reduce<SeriesMarkLine[]>((acc, pr) => {
    const timestamp = calculateInoculationTime(
      timeAlignment,
      pr.startTimestamp.getTime(),
      pr.inoculationTimestamp?.getTime(),
    );

    if (timestamp === undefined) {
      return acc;
    }

    return [
      ...acc,
      {
        color: inSingleView ? DATA_COLORS.gray : pr.color,
        name: 'Inoculation',
        timestamp,
      } satisfies SeriesMarkLine,
    ];
  }, []);
