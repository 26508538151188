import React, { useState, FunctionComponentElement, useCallback } from 'react';
import { ActivityIndicator, Modal, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { TValue } from '../editable-data-table/editable-data-table.definitions';

import { DataPointCreateModalProps, DataPoint } from './data-point-create-modal.definitions';
import { ModalContent } from './modal-content';
import {
  getNewDataPoints,
  createNewDataPoint,
  updateDataPoints,
  getSavedRowsIndexes,
} from './data-points-create-modal.helpers';
import { useValidate } from './data-point-create-modal.validation';

function DataPointCreateModal({
  dataPoints: dataPointsSaved,
  dataTrack,
  trigger,
  defaultOpen,
  open,
  isLoading,
  isPending,
  isAddDataPointsError,
  isDeleteDataPointsError,
  onChangeModal,
  saveDataPoints,
}: DataPointCreateModalProps): FunctionComponentElement<DataPointCreateModalProps> {
  const [dataPoints, setDataPoints] = useState<DataPoint[]>(
    dataPointsSaved.length ? dataPointsSaved : [createNewDataPoint()],
  );
  const [deletedRows, setDeletedRows] = useState<string[]>([]);
  const [savedDataPoints, setSavedDataPoints] = useState<DataPoint[]>(dataPointsSaved);
  const { isValid, validationError, timestampDuplicates } = useValidate(
    dataPoints,
    dataPointsSaved.length,
    deletedRows.length,
  );

  // rows are not editable
  const savedRows = getSavedRowsIndexes(savedDataPoints.length);

  React.useEffect(() => {
    if (!isLoading) {
      // Add an empty data point row if dataPointsSaved are empty
      setDataPoints(() => {
        if (dataPoints.length && !dataPointsSaved.length) {
          return [createNewDataPoint()];
        }
        return dataPointsSaved;
      });
      setSavedDataPoints(dataPointsSaved);
    }
  }, [isLoading]);

  // add new empty data point
  const addDataPoint = useCallback(() => {
    setDataPoints((prev) => [...prev, createNewDataPoint()]);
  }, []);

  // delete last data point
  const deleteDataPoint = () => {
    if (dataPoints.length > savedDataPoints.length) {
      const points = [...dataPoints];
      points.pop();
      setDataPoints(points);
    }
  };

  const handleDeleteSelectedRow = (idx: number) => {
    setDeletedRows((prev) => [...prev, dataPoints[idx].ts.toString()]);
    setDataPoints((prev) => {
      const points = [...prev];
      points.splice(idx, 1);
      return points;
    });
    setSavedDataPoints((prev) => {
      const points = [...prev];
      points.splice(idx, 1);
      return points;
    });
  };

  // add save data points to the data track
  const saveNewDataPoints = () => {
    if (isValid) {
      const newPoints = getNewDataPoints(dataPoints, savedDataPoints.length);
      saveDataPoints(newPoints, deletedRows);
    }
  };

  const updateTableData = (rowIndex: number, columnId: string, value: TValue) => {
    setDataPoints((prev: DataPoint[]) => updateDataPoints(prev, rowIndex, columnId, value));
  };

  const changeModal = (isOpen: boolean) => {
    setDataPoints([createNewDataPoint()]);
    onChangeModal(isOpen);
  };

  return (
    <Modal
      open={open}
      defaultOpen={defaultOpen}
      trigger={trigger}
      onOpenChange={changeModal}
      size="sm"
      title={
        <FormattedMessage
          defaultMessage="Create Data Points"
          id="awnsKo"
          description="Create Data Points Title"
        />
      }
    >
      <Modal.Content>
        {isAddDataPointsError && (
          <div className="mb-6">
            <NotificationMessage status="error">
              <FormattedMessage
                defaultMessage="An internal error occurred while adding new data points. Please try again."
                id="LNSEI7"
                description="Create Data Points Error message"
              />
            </NotificationMessage>
          </div>
        )}

        {isDeleteDataPointsError && (
          <div className="mb-6">
            <NotificationMessage status="error">
              <FormattedMessage
                defaultMessage="An internal error occurred while saving changes to the data points. Please try again."
                id="N0bqgX"
                description="Create Data Points Error message"
              />
            </NotificationMessage>
          </div>
        )}
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <ModalContent
            dataPoints={dataPoints}
            dataTrack={dataTrack}
            savedRows={savedRows}
            updateTableData={updateTableData}
            addDataPoint={addDataPoint}
            deleteDataPoint={deleteDataPoint}
            deleteSelectedRow={handleDeleteSelectedRow}
            errorMessage={validationError}
            timestampDuplicates={timestampDuplicates}
          />
        )}
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancelDataTrackButton">
            <FormattedMessage
              description="Add Data Points: label for cancel button"
              defaultMessage="Cancel"
              id="9f8vwg"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          disabled={!isValid}
          variant="highlight"
          onClick={saveNewDataPoints}
          data-testid="createDataTrackButton"
          tailIcon={isPending && <ActivityIndicator size="sm" />}
        >
          <FormattedMessage
            description="Save Data Points: label for save button"
            defaultMessage="Save"
            id="QkVROR"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataPointCreateModal;
