import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { DataTrackEmptyModalSuccessProps } from './data-track-empty-modal.definitions';

function DataTrackEmptyModalSuccess({
  open,
  onOpenChange,
  defaultOpen,
  trigger,
}: DataTrackEmptyModalSuccessProps) {
  return (
    <Modal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      title={
        <FormattedMessage
          defaultMessage="Data Tracks Created Successfully"
          id="u5GtMR"
          description="Create Empty Data Track: Title"
        />
      }
    >
      <Modal.Content>
        <FormattedMessage
          description="Calculate Data Track Success Message"
          defaultMessage="The data tracks were created successfully. You can now select each data track in the data track list and add data points to it."
          id="U0B7yd"
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Close"
              id="4SRIi7"
              description="Create Empty Data Track: Close"
            />
          </Modal.Button>
        </Modal.Close>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackEmptyModalSuccess;
