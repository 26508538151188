import max from '../../common/utils/max';
import min from '../../common/utils/min';
import roundToCommonDivisor from '../../common/utils/round-to-common-divisor/round-to-common-divisor';
import { YAxisRange } from '../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import { SeriesName } from '../chart-groups';
import { DataPointObject } from '../combined-chart/combined-chart.definitions';

import {
  GroupedSeriesYAxisLabel,
  Y_AXIS_RANGE_BUFFER,
  YAxisDescriptor,
} from './chart-y-axis.definitions';

export function getYAxisDescriptors<T extends GroupedSeriesYAxisLabel>(
  groupedSeries: Record<string, Array<T>>,
): YAxisDescriptor[] {
  return Object.entries(groupedSeries).map<YAxisDescriptor>(([groupName, series]) => ({
    name: groupName,
    label: series.at(0)?.yAxisLabel ?? groupName,
  }));
}

export function getDataTrackDefaultYAxisRange(dataTrack: DataPointObject[]) {
  const values = dataTrack.map((dt) => dt.v);

  const minimum = min(values);
  const maximum = max(values);

  const rangeBasedMinimum = Math.floor(minimum - (maximum - minimum) * Y_AXIS_RANGE_BUFFER);
  const discriminatedMinimum = minimum < 0 ? rangeBasedMinimum : Math.max(0, rangeBasedMinimum);

  const flooredMinimum = Math.floor(discriminatedMinimum);
  const ceiledMaximum = Math.ceil(maximum);

  const maxOrPlusOne = ceiledMaximum > flooredMinimum ? ceiledMaximum : ceiledMaximum + 1;

  return {
    min: flooredMinimum,
    max: maxOrPlusOne,
  };
}

/**
 * calculate the default min and max values for the YAxis of a series
 */
export function getDefaultYAxisMinMax(
  data: [string, DataPointObject[]][],
): Map<SeriesName, YAxisRange> {
  const names = data.map(([name]) => name);
  const ranges = data.map(([_, dataPoints]) => getDataTrackDefaultYAxisRange(dataPoints));

  const mins = ranges.map((i) => i.min);
  const maxes = ranges.map((i) => i.max);

  const divisor = 5;
  const normalizedMins = roundToCommonDivisor(mins, divisor, false);
  const normalizedMaxes = roundToCommonDivisor(maxes, divisor, true);

  return new Map(
    names.map((name, index) => [
      name,
      { min: normalizedMins[index], max: normalizedMaxes[index] } satisfies YAxisRange,
    ]),
  );
}
