import { palettes } from '@biss/react-horizon-web';

import {
  DataTrack,
  DataPointObject,
  DataTrackFromTimestampItem,
  LatestDataPointObject,
} from '../../shared/common/types/setup';

import { SeriesName } from '../../shared/components/time-series-chart';
import { YAxisRange } from '../scenes/setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';
import max from '../../shared/common/utils/max';
import min from '../../shared/common/utils/min';

export const SETUP_TIME_IN_MINUTES = 5;

export const MINUTES = 60;
export const SECONDS = 60;
export const MILLISECONDS = 1000;

export const MILLISECONDS_IN_HOUR = MINUTES * SECONDS * MILLISECONDS;
export function filterColors(colors: string[], colorsFilter: string[]) {
  return colors.filter((item) => !colorsFilter.includes(item));
}

export function getGraphColors() {
  return Object.keys(palettes.categorical_unique);
}

export function filterAndSortDataPoints(dataPoints: DataPointObject[]) {
  const timestamps = dataPoints.map(({ ts }) => ts);
  const filtered = dataPoints.filter(({ ts }, index) => !timestamps.includes(ts, index + 1));
  filtered.sort((a, b) => a.ts - b.ts);
  return filtered;
}

export function isPrevDataPoints(dataPoints: DataPointObject[], dataPointsLast: DataPointObject[]) {
  if (!dataPointsLast.length || !dataPoints.length) {
    return false;
  }
  return dataPointsLast[0].ts <= dataPoints[dataPoints.length - 1].ts;
}

export function combineDataPoints(
  dataTrackList: DataTrack[],
  lastDataPoints?: LatestDataPointObject[],
): DataTrack[] {
  if (!lastDataPoints?.length) {
    return dataTrackList;
  }
  return dataTrackList.map((track) => {
    const trackData = lastDataPoints.find((item) => track.dataTrackId === item.dataTrackId);
    if (trackData) {
      const dataPointsCombined = [...track.dataPoints, ...trackData.dataPoints];
      const dataPoints = isPrevDataPoints(track.dataPoints, trackData.dataPoints)
        ? filterAndSortDataPoints(dataPointsCombined)
        : dataPointsCombined;
      return {
        ...track,
        dataPoints,
      };
    }
    return track;
  });
}

// returns true if less than 5 minutes have passed since the setup started
export function getIsSetupSetting(date?: Date) {
  const seconds = 60;
  const milliseconds = 1000;
  if (!date) {
    return false;
  }
  const now = new Date();
  const setupTimeInMiliseconds = SETUP_TIME_IN_MINUTES * seconds * milliseconds;
  const timeDifference = now.getTime() - date.getTime();
  return timeDifference < setupTimeInMiliseconds;
}

export function isFetched(isFetching: boolean, isFetchingPrev: boolean) {
  return !isFetching && isFetchingPrev;
}

export function getLastTimestamp(dataTracks: LatestDataPointObject[]): number | undefined {
  const timestamps = dataTracks.reduce((timestamp: number[], track: LatestDataPointObject) => {
    if (track.dataPoints.length) {
      timestamp.push(track.dataPoints[track.dataPoints.length - 1].ts);
    }
    return timestamp;
  }, []);

  return timestamps.length ? max(timestamps) : undefined;
}

export function getFirstTimestamp(dataTracks: LatestDataPointObject[]): number {
  const timestamps = dataTracks.map((track: LatestDataPointObject) => track.dataPoints[0].ts);
  return min(timestamps);
}

export function isSameFirstDataPoint(firstTimestamp: number, firstTimestampPrev?: number | null) {
  return firstTimestampPrev && firstTimestamp === firstTimestampPrev;
}

export function isStartGapRecovery(isDisconnected: boolean, isDisconnectedPrev: boolean) {
  return isDisconnected === false && isDisconnectedPrev === true;
}

export function getGapRecoveryTimestamp(
  dataTracksTimetamps: DataTrackFromTimestampItem[],
): string | null {
  const timestamps: number[] = [];
  dataTracksTimetamps.forEach((track: DataTrackFromTimestampItem) => {
    if (track.timestamp) {
      timestamps.push(new Date(track.timestamp).getTime());
    }
  }, []);
  return timestamps.length ? new Date(min(timestamps)).toISOString() : null;
}

export function getDataTrackLastTimestamp(
  dataTracksTimestamp: DataTrackFromTimestampItem[],
  timestamp?: string | null,
): DataTrackFromTimestampItem[] {
  if (timestamp) {
    return dataTracksTimestamp.map((track: DataTrackFromTimestampItem) => ({
      ...track,
      timestamp,
    }));
  }
  return dataTracksTimestamp;
}

export function subtractHours(date: Date, hours: number) {
  const dateToMilliseconds = date.getTime();
  const hoursToMilliseconds = hours * MILLISECONDS_IN_HOUR;
  return new Date(dateToMilliseconds - hoursToMilliseconds);
}

export function convertYAxisRangesToMap(
  yAxisRanges: Record<SeriesName, YAxisRange>,
): Map<SeriesName, YAxisRange> {
  return new Map(Object.entries(yAxisRanges));
}
