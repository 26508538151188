import React, { useState } from 'react';
import { ActivityIndicator } from '@biss/react-horizon-web';

import { SeriesName } from '../../../../../shared/components/time-series-chart';
import { MarkedDataTrack } from '../process-record-visualization.definitions';
import { useDataTrackColorization } from '../process-record-visualization.helpers';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import useMultipleAnalyticsDataTracks from '../../../../../shared/common/hooks/use-multiple-analytics-data-tracks';
import { DataTrack } from '../../../../../shared/common/types/process-record';
import ProcessRecordVisualizationChartSelection from '../process-record-visualization-chart-selection';
import { DataTrackType } from '../../../../../shared/common/types/setup';
import { getDefaultYAxisMinMax } from '../../../../../shared/charts/chart-y-axis/chart-y-axis';
import { formatLegend } from '../../../../../shared/components/time-series-chart/time-series-chart.helpers';
import { SharedSeries } from '../process-record-visualization-chart-selection/process-record-visualization-chart-selection.definitions';

import useLogger from '../../../../../shared/common/hooks/use-logger/use-logger';

import TrackedEvent from '../../../../../shared/common/tracked-event';

import { ProcessRecordVisualizationChartProps } from './process-record-visualization-chart.definitions';
import {
  calculateOldestDataPointTimestamp,
  calculateYoungestDataPointTimestamp,
  getFetchableDataTracks,
  getMarkLines,
  markDataTracks,
} from './process-record-visualization-chart.helpers';

function ProcessRecordVisualizationChart({
  coloredProcessRecords,
  selectedDataTrackTypes,
  inSingleView,
  chartType,
  timeAlignment,
  xAxisFormat,
  isHorizontalZoomSynced,
  isVerticalZoomSynced,
}: ProcessRecordVisualizationChartProps) {
  const logger = useLogger();

  // range of the viewable and zoom-able area on the y axis of the chart
  const [overwrittenYAxisRanges, setOverwrittenYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    new Map(),
  );

  // fetch data tracks, or load them from the cache
  const dataTrackQueries = useMultipleAnalyticsDataTracks(
    // calculate fetch-able data tracks
    getFetchableDataTracks(coloredProcessRecords, selectedDataTrackTypes),
  );

  const fetchedDataTracks: DataTrack[] = dataTrackQueries.flatMap((query) =>
    query.data !== undefined && query.isLoading === false ? query.data : [],
  );
  const lastUpdatedTimestampSum = dataTrackQueries.reduce(
    (acc, { dataUpdatedAt }) => acc + dataUpdatedAt,
    0,
  );
  const isLoadingDataTracks = dataTrackQueries.some((query) => query.isLoading);

  // data track colorization utility
  const { getColor } = useDataTrackColorization(selectedDataTrackTypes);

  // mark every fetched data track with a reference to its process record
  // colorize data tracks
  // align data track data points relative
  const markedDataTracks = markDataTracks(
    coloredProcessRecords,
    fetchedDataTracks,
    inSingleView,
    getColor,
    timeAlignment,
  );

  // map selected data track types to data tracks with the same type
  // if no data tracks are available with the chosen type, ignore the type
  const selectedTypeToMarkedDataTracks: Record<DataTrackType, MarkedDataTrack[]> =
    Object.fromEntries(
      selectedDataTrackTypes.flatMap((type) => {
        const dataTracksOfType = markedDataTracks.filter((dt) => dt.dataTrackType === type);
        return dataTracksOfType.length > 0 ? [[type, dataTracksOfType]] : [];
      }),
    );

  // data tracks whose type is selected
  const selectedMarkedDataTracks = Object.values(selectedTypeToMarkedDataTracks).flat();

  const seriesWithMetaData = selectedMarkedDataTracks.map((dataTrack) =>
    Object.assign(
      {
        id: dataTrack.dataTrackId,
        title: dataTrack.displayName,
        color: dataTrack.color,
        dataPoints: dataTrack.dataPoints,
        yAxisLabel: dataTrack.engineeringUnit,
        legendOverwrite:
          chartType === 'combined'
            ? dataTrack.displayName
            : formatLegend(dataTrack.processRecord.displayName, dataTrack.processRecord.unit),
        //
      } satisfies SharedSeries,
      {
        type: dataTrack.dataTrackType,
        processRecord: dataTrack.processRecord,
      },
    ),
  );

  // configure mark lines
  const markLines = getMarkLines(coloredProcessRecords, timeAlignment, inSingleView);

  // combine default ranges with overwritten ones
  const yAxisRanges = new Map(
    Array.from(
      // default
      getDefaultYAxisMinMax(
        selectedMarkedDataTracks.map(({ displayName, dataPoints }) => [displayName, dataPoints]),
      ).entries(),
    ).concat(
      // overwritten
      Array.from(overwrittenYAxisRanges.entries()),
    ),
  );

  const startTime = calculateOldestDataPointTimestamp(selectedMarkedDataTracks);
  const stopTime = calculateYoungestDataPointTimestamp(selectedMarkedDataTracks);

  // show data tracks of the same type in the same graphs
  const groups = Object.fromEntries(
    selectedDataTrackTypes.map((type) => [
      type,
      seriesWithMetaData.filter((s) => s.type === type).map((s) => s.id),
    ]),
  );

  const handleChartZoom = () => {
    logger.trackEvent(TrackedEvent.ZoomingInTheGraph);
  };

  return (
    <div className="px-4">
      {isLoadingDataTracks && <ActivityIndicator showLabel label="Loading data track(s)" />}

      <ProcessRecordVisualizationChartSelection
        chartType={chartType}
        data={seriesWithMetaData}
        // bust the cache and reinitialize the chart when data track data is edited.
        dataCacheBustKey={lastUpdatedTimestampSum.toString()}
        startTime={startTime}
        stopTime={stopTime}
        defaultYAxisRange={yAxisRanges}
        markLines={markLines}
        timeStampFormat={xAxisFormat}
        onYAxisRangeChange={setOverwrittenYAxisRanges}
        groups={groups}
        isHorizontalZoomSynced={isHorizontalZoomSynced}
        isVerticalZoomSynced={isVerticalZoomSynced}
        onZoom={handleChartZoom}
      />
    </div>
  );
}

export default ProcessRecordVisualizationChart;
