export interface GroupedSeriesYAxisLabel {
  yAxisLabel: string;
}

export type YAxisDescriptor = {
  name: string;
  label: string;
};

export const Y_AXIS_RANGE_BUFFER = 0.2;
