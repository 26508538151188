import {
  ProcessRecordAttributes,
  ProcessRecordId,
} from '../../../shared/common/types/process-record';
import { DataTrackDescriptor } from '../../../shared/common/types/setup';
import { ProcessRecordOptimizationVariable } from '../../common/types';

export type RecipeOptimizationTransferTileProps = {
  processRecordId: ProcessRecordId;

  dataTracks: DataTrackDescriptor[];

  attributes: ProcessRecordAttributes;

  /** the previous mapping data */
  mappings: ProcessRecordOptimizationVariable[] | undefined;

  /** whether the mappings are being loaded */
  isLoading?: boolean;

  /** whether the mappings failed to load */
  isError?: boolean;

  /** disable editing the mappings */
  disabled?: boolean;

  /** used in tests only */
  selectVariableDefaultOpen?: boolean;
};

export type SelectedVariableCodeAndGroup = {
  /** variable name on DataHow */
  variableCode: string;

  /** variable group on DataHow */
  variableGroupCode: string;
};

/**
 * map used to rename variableCodes to more readable identifiers
 */
export const VARIABLE_RENAME_MAP = new Map([
  ['VCD', 'Viable Cell Density'],
  ['Volume', 'Initial Volume'],
]);
