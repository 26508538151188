/** Describes the range of a zoom zoom level in percent.  */
export type ZoomRange = {
  /** Start of the zoom range in percent. Value between 0 and 100. */
  start: number;

  /** End of the zoom range in percent. Value between 0 and 100. */
  end: number;
};

export type DataZoomParam = {
  batch?: DataZoomParam[];
  type?: string;
  from?: string;
  dataZoomId?: string;
  animation?: Animation;
  start: number;
  end: number;
};

/** Identifies the inside zoom on the x-axis. */
export const CHART_X_AXIS_ZOOM_INSIDE_ID = 'x-axis-inside';
/** Identifies the slider that controls the x-axis zoom. */
export const CHART_X_AXIS_ZOOM_SLIDER_ID = 'x-axis-slider';
/** Identifies the slider that controls the y-axis zoom. */
export const CHART_Y_AXIS_ZOOM_SLIDER_ID = 'y-axis-slider';

export const CHART_ALL_AXIS_SLIDERS = 'all-axis-sliders';

export const CHART_INITIAL_ZOOM = { start: 0, end: 100 };
