import React, { useEffect, useState } from 'react';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';

import { SeriesName } from '../../../../shared/components/time-series-chart';
import TileWithSkeleton from '../../../../shared/tile-with-skeleton';
import { Y_AXIS_RANGE } from '../../../common/types/setup';
import { YAxisRange } from '../../setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';
import { ChartView } from '../../../../shared/charts/chart-toolbox/chart-toolbox.definitions';

import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';

import TrackedEvent from '../../../../shared/common/tracked-event';

import { SetupTimeSeriesChartProps } from './setup-time-series-chart.definitions';
import { getYAxisRanges } from './setup-time-series-chart.helpers';
import SetupTimeSeriesChartVisualization from './setup-time-series-chart-visualization.tsx';
import { MAX_GROUP_LENGTH } from './setup-time-series-chart-visualization.tsx/setup-time-series-chart-visualization.definitions';

function SetupTimeSeriesChart({
  startTimestamp,
  stopTimestamp,
  seriesMarkLines,
  isShowLoading,
  series,
  seriesData,
  groups,
}: SetupTimeSeriesChartProps) {
  const logger = useLogger();

  const groupNames = Object.keys(groups);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const storageYAxis = useReadLocalStorage<Record<SeriesName, YAxisRange>>(Y_AXIS_RANGE);

  const defaultRanges = getYAxisRanges(series, storageYAxis);

  // range of the viewable and zoom-able area on the y axis of the chart
  const [overwrittenYAxisRanges, setOverwrittenYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    new Map(),
  );

  // combine default ranges with overwritten ones
  const yAxisRanges = new Map(
    Array.from(defaultRanges.entries()).concat(
      // overwritten
      Array.from(overwrittenYAxisRanges.entries()),
    ),
  );

  const [chartView, setChartView] = useState<ChartView>('combined');

  // set the view of the chart to "split view" when too many groups are selected
  // the view will be kept in "split view" until the user changes it manually
  useEffect(() => {
    const isLimitExceeded = chartView === 'combined' && groupNames.length > MAX_GROUP_LENGTH;

    if (isLimitExceeded) {
      setChartView('split');
    }
  }, [groupNames.length]);

  const stopTime = stopTimestamp?.getTime() || new Date().getTime();

  const handleChartZoom = () => {
    logger.trackEvent(TrackedEvent.ZoomingInTheGraph);
  };

  return (
    <TileWithSkeleton
      className={`${isMobile ? 'min-h-[383px]' : 'min-h-[507px]'} p-4`}
      isLoading={isShowLoading}
      data-testid="detail-graph"
    >
      <SetupTimeSeriesChartVisualization
        startTimestamp={startTimestamp?.getTime()}
        seriesMarkLines={seriesMarkLines}
        seriesData={seriesData}
        groups={groups}
        stopTimestamp={stopTime}
        yAxisRanges={yAxisRanges}
        setYAxisRanges={setOverwrittenYAxisRanges}
        chartView={chartView}
        setChartView={setChartView}
        onZoom={handleChartZoom}
      />
    </TileWithSkeleton>
  );
}

export default SetupTimeSeriesChart;
