import React, { FunctionComponentElement } from 'react';
import { useIntl } from 'react-intl';

import { ToggleButton } from '@biss/react-horizon-web';

import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';

import { CHART_VIEW, ChartToolboxProps, ChartView } from './chart-toolbox.definitions';
import { renderIcon } from './chart-toolbox.helpers';

function ChartToolbox({
  showChartViewToggle = true,
  chartView = CHART_VIEW[0],
  setChartView,
  children,
  disabled = false,
}: ChartToolboxProps): FunctionComponentElement<ChartToolboxProps> {
  const logger = useLogger();
  const intl = useIntl();

  const onClick = (value: ChartView) => {
    logger.trackEvent(TrackedEvent.ToggleSplitOrCombinedChart);
    setChartView?.(value);
  };

  return (
    <div className="mb-4 flex items-center justify-end" data-testid="ChartToolbox">
      {children}
      {showChartViewToggle && (
        <ToggleButton value={chartView} onValueChange={onClick}>
          {CHART_VIEW.map((item) => (
            <ToggleButton.Item key={item} value={item} disabled={disabled || item === chartView}>
              <div className="ml-1 h-6 w-6">{renderIcon(intl, item)}</div>
            </ToggleButton.Item>
          ))}
        </ToggleButton>
      )}
    </div>
  );
}
export default ChartToolbox;
