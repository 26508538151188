/**
 * App config feature keys
 */
const enum FKey {
  // monitoring-detail-view

  MONITORING_DETAIL_VIEW_PROTOTYPING = 'BIOCL-2237/monitoring-detail-view-prototyping',

  MONITORING_SET_TRACK_ALERT_LIMITS = 'rel_set-track-alert-limits',

  MONITORING_DWC_EVENT_NOTIFICATION = 'rel_dwc-event-notification',

  // process-record-visualization

  /** this key is only used within tests */
  TEST_KEY = 'TEST_KEY',
}

export default FKey;
