import { SeriesName } from '../../../components/time-series-chart';
import { yAxisDataRangeMin } from '../../../components/time-series-chart/time-series-chart.helpers';
import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import {
  COMBINED_CHART_LEFT_OFFSET_PX,
  COMBINED_CHART_Y_AXIS_FONT_SIZE,
  COMBINED_CHART_Y_AXIS_LABEL_DECIMALS,
} from '../combined-chart.config';

import YAxisDescriptor from './combined-chart-y-axis-setup.definitions';

export function getYAxisRangeForLabel(
  label: string,
  ranges: Map<SeriesName, YAxisRange>,
): Partial<YAxisRange> {
  const range = ranges.get(label);
  if (range === undefined) {
    return {};
  }
  return range;
}

export function getYAxisEchartsConfig(
  content: Array<YAxisDescriptor>,
  ranges: Map<SeriesName, YAxisRange>,
) {
  const yAxisFormatter = (value: number) =>
    parseFloat(value.toFixed(COMBINED_CHART_Y_AXIS_LABEL_DECIMALS));

  return content.map(({ label, name }, index) => {
    const { min, max } = getYAxisRangeForLabel(name, ranges);

    return {
      type: 'value',
      name: label,
      gridIndex: 0,
      position: 'left',
      offset: index * COMBINED_CHART_LEFT_OFFSET_PX,
      alignTicks: true,
      animation: false,
      axisLine: {
        show: true,
      },
      axisLabel: {
        formatter: yAxisFormatter,
      },
      nameTextStyle: {
        fontSize: COMBINED_CHART_Y_AXIS_FONT_SIZE,
      },
      triggerEvent: true,
      // TODO: (BIOCL-4457) set min value with 20% below actual min value
      min: min ?? yAxisDataRangeMin,
      max: max ?? null,
    };
  });
}
