import { z } from 'zod';

export const DISPLAY_NAME_REQUIRED_ERROR_MESSAGE = 'A display name must be provided.';

export const DISPLAY_NAME_DUPLICATE_ERROR_MESSAGE = 'A data track with this name already exists.';

export const dataTrackCreationInfoSchema = z.object({
  displayName: z.string().trim(),
  engineeringUnit: z.string().trim(),
});
export type DataTrackCreationInfo = z.infer<typeof dataTrackCreationInfoSchema>;

export const dataTrackEmptyFormSchema = z.object({
  dataTracks: z.array(dataTrackCreationInfoSchema),
});

/**
 * @param displayNames
 * @returns the indices of duplicate entries
 */
export const validateDataTrackEntries = (
  dataTrackEntries: DataTrackCreationInfo[],
  takenDisplayNames: string[],
): Map<number, string> => {
  const allNames = [...dataTrackEntries.map((i) => i.displayName), ...takenDisplayNames].filter(
    (i) => i !== '',
  );

  return new Map(
    dataTrackEntries.flatMap<[number, string]>((dt, index) => {
      if (dt.engineeringUnit !== '' && dt.displayName === '') {
        return [[index, DISPLAY_NAME_REQUIRED_ERROR_MESSAGE]];
      }

      if (allNames.filter((displayName) => displayName === dt.displayName).length > 1) {
        return [[index, DISPLAY_NAME_DUPLICATE_ERROR_MESSAGE]];
      }

      return [];
    }),
  );
};
