import React from 'react';
import { Button, Input, Modal, NotificationMessage, PlusIcon } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import classNames from 'classnames';

import useSubmitDataTracks from '../../common/hooks/use-submit-data-tracks';
import useProcessRecord from '../../../shared/common/hooks/use-process-record';
import LoadingIndicator from '../../../shared/components/loading-indicator';
import useLogger from '../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../shared/common/tracked-event';

import {
  DataTrackEmptyModalProps,
  DataTrackEmptyFormData,
} from './data-track-empty-modal.definitions';
import {
  validateDataTrackEntries,
  dataTrackEmptyFormSchema,
} from './data-track-empty-modal.validators';
import DataTrackEmptyModalSuccess from './data-track-empty-modal-success';

function DataTrackEmptyModal({
  processRecordId,
  open,
  onOpenChange,
  trigger,
  defaultIsDone = false,
  defaultFormData = {
    dataTracks: [
      {
        displayName: '',
        engineeringUnit: '',
      },
    ],
  },
}: DataTrackEmptyModalProps) {
  const intl = useIntl();
  const logger = useLogger();

  const { data: processRecord, isLoading } = useProcessRecord({
    processRecordId,
    withDataPointsOf: 'none',
  });

  const {
    mutate: submitDataTracks,
    isPending,
    error,
    isSuccess,
  } = useSubmitDataTracks(processRecordId);

  /** the single api that handles all of the form */
  const { handleSubmit, control, watch } = useForm<DataTrackEmptyFormData>({
    mode: 'onBlur',
    resolver: zodResolver(dataTrackEmptyFormSchema),
    defaultValues: defaultFormData,
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'dataTracks',
  });

  // wait until process records taken names are there
  if (isLoading || processRecord === undefined) {
    return <LoadingIndicator />;
  }

  const takenNames = processRecord.dataTracks.map((dt) => dt.displayName);
  const [dataTracks] = watch(['dataTracks']);

  const indexToErrorMessageMap = validateDataTrackEntries(dataTracks ?? [], takenNames);
  const committableDataTrackEntries = (dataTracks ?? []).filter((dt) => dt.displayName.length > 0);
  const isThereASingleCommittableEntry = committableDataTrackEntries.length > 0;

  const handleCreateClick = () => {
    logger.trackEvent(TrackedEvent.AddCustomDataTrack);

    submitDataTracks(
      committableDataTrackEntries.map((value) => ({
        displayName: value.displayName,
        dataTrackType: value.displayName,
        engineeringUnit: value.engineeringUnit ?? '',
        dataPoints: [],
      })),
    );
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    // prevent closing the modal when loading
    if (isPending) {
      return;
    }

    onOpenChange?.(isOpen);
  };

  if (defaultIsDone || isSuccess) {
    return <DataTrackEmptyModalSuccess open={open} onOpenChange={handleModalOpenChange} />;
  }

  const placeholder = intl.formatMessage({
    defaultMessage: 'e.g. DO.PV',
    description: 'Create Empty Data Track: Placeholder for Data Track Name input',
    id: 'fqDeLs',
  });

  const label = intl.formatMessage({
    defaultMessage: 'Data Track Name',
    description: 'Create Empty Data Track: Data Track Name',
    id: 'n73c8t',
  });

  const engineeringUnitLabel = intl.formatMessage({
    defaultMessage: 'Engineering Unit',
    description: 'Create Empty Data Track: Engineering Unit Label',
    id: 'y14PYS',
  });

  const engineeringUnitPlaceholder = intl.formatMessage({
    defaultMessage: 'e.g. %DO',
    description: 'Create Empty Data Track: Placeholder for Data Track Name input',
    id: 'hg5PAL',
  });

  return (
    <Modal
      size="sm"
      open={open}
      onOpenChange={handleModalOpenChange}
      trigger={trigger}
      title={
        <FormattedMessage
          defaultMessage="Create Data Tracks"
          id="uQ+g70"
          description="Create Empty Data Track: Title"
        />
      }
    >
      <Modal.Content>
        <form onSubmit={handleSubmit(handleCreateClick)} className="flex flex-col gap-4">
          {error && <NotificationMessage status="error">{error.message}</NotificationMessage>}

          {fields.map((item, index) => (
            <div className="flex w-full flex-row gap-4" key={item.id}>
              <div className={classNames('grid w-full grid-cols-2 gap-4')}>
                <Controller
                  control={control}
                  name={`dataTracks.${index}.displayName`}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading -- as prescribed by library
                      {...field}
                      value={field.value ?? ''}
                      expand="auto"
                      error={indexToErrorMessageMap.get(index)}
                      disabled={isPending}
                      placeholder={placeholder}
                      label={label}
                      tabIndex={index === 0 ? -1 : undefined}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`dataTracks.${index}.engineeringUnit`}
                  render={({ field }) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading -- as prescribed by library
                      {...field}
                      value={field.value ?? ''}
                      expand="auto"
                      disabled={isPending}
                      placeholder={engineeringUnitPlaceholder}
                      label={engineeringUnitLabel}
                      tabIndex={index === 0 ? -1 : undefined}
                    />
                  )}
                />
              </div>
            </div>
          ))}

          <div>
            <Button
              mood="neutral"
              leftIcon={<PlusIcon />}
              onClick={() => append({ displayName: '', engineeringUnit: '' })}
              data-testid="add-new-data-track-button"
            >
              <FormattedMessage
                defaultMessage="Data Track"
                description="Create Empty Data Track: Add another data track button"
                id="FbPnop"
              />
            </Button>
          </div>
        </form>
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Cancel"
              id="rzllvN"
              description="Create Empty Data Track: Cancel"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          variant="highlight"
          data-testid="create-data-track-button"
          onClick={handleCreateClick}
          disabled={indexToErrorMessageMap.size > 0 || isThereASingleCommittableEntry === false}
          isLoading={isPending}
        >
          {isPending ? (
            <FormattedMessage
              defaultMessage="Creating"
              id="aDKBCP"
              description="Create Empty Data Track: Creating"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Create"
              id="MVYaT3"
              description="Create Empty Data Track: Create"
            />
          )}
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackEmptyModal;
