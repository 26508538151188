import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  FunctionComponentElement,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  EppendorfRole,
  MemberRole,
  Roles,
  isEppendorfAdministrator,
  useAuthContext,
} from '@biss/react-auth-web';
import { Modal } from '@biss/react-horizon-web';
import { useNavigate } from 'react-router-dom';

import { useInviteNewMember, useFormValidation } from '../../../common/hooks';
import MemberGeneralInfo from '../../../components/member-general-info/member-general-info';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import LoadingIndicator from '../../../../shared/components/loading-indicator/loading-indicator';

import { NewInvitationFormState } from './new-invitation.definitions';
import InvitationResult from './invitation-result';

function NewInvitation(): FunctionComponentElement<unknown> {
  const { account } = useAuthContext();
  const intl = useIntl();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const onClose = () => {
    navigate(`${RouteDefinition.UserManagement}/members`);
  };
  const roles = (
    account && isEppendorfAdministrator(account)
      ? (['EppendorfAdministrator', 'EppendorfService'] satisfies EppendorfRole[])
      : (['Member', 'Administrator'] satisfies MemberRole[])
  ) satisfies Roles;
  const { mutate, data: member, status, isError, error, isPending } = useInviteNewMember();

  const [inputs, setInputs] = useState<NewInvitationFormState>({
    memberDisplayName: '',
    memberEmail: '',
    memberRoles: [],
    rolesCheckedState: new Map(roles.map((role) => [role, false])),
    mfa: false,
    errors: new Map(),
    validity: new Map([
      ['memberDisplayName:', true],
      ['memberEmail', false],
      ['memberRoles', false],
    ]),
  });

  const { updateErrorsFromFocusEvent, updateErrorsAndValidityFromChangeEvent } =
    useFormValidation();
  function getRoles(rolesCheckedState: Map<MemberRole | EppendorfRole, boolean>): Roles {
    let assignedroles: Roles = [];
    rolesCheckedState.forEach((checked, role, _) => {
      if (checked) {
        assignedroles = [...assignedroles, role] as Roles;
      }
    });
    return assignedroles;
  }

  const setFormState = (state: NewInvitationFormState): NewInvitationFormState => {
    const newRoles = getRoles(state.rolesCheckedState);

    return {
      ...state,
      memberRoles: newRoles,
    };
  };

  useEffect(() => {
    setInputs((values) =>
      setFormState({ ...values, mfa: !!(account && isEppendorfAdministrator(account)) }),
    );
  }, [account]);

  const updateErrors = (
    state: NewInvitationFormState,
    event: FocusEvent<HTMLInputElement>,
  ): NewInvitationFormState => ({
    ...state,
    errors: updateErrorsFromFocusEvent(state, event),
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((values) =>
      setFormState({
        ...values,
        [name]: value,
        ...updateErrorsAndValidityFromChangeEvent(values, event),
      }),
    );
  };

  const handleRolesChange = (name: MemberRole | EppendorfRole, checked: boolean) => {
    setInputs((values) => {
      const rolesCheckedState = values.rolesCheckedState.set(name, checked);
      const newRoles = getRoles(rolesCheckedState);
      const validity = new Map<string, boolean>(values.validity);
      validity.set('memberRoles', newRoles.length > 0);
      return setFormState({
        ...values,
        rolesCheckedState,
        validity,
      });
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    mutate(
      {
        memberDisplayName: inputs.memberDisplayName,
        memberEmail: inputs.memberEmail,
        memberRoles: inputs.memberRoles,
        mfa: inputs.mfa,
      },
      {
        onSuccess: () => {
          setOpen(false);
          onClose();
        },
      },
    );
  };

  const handleMfaChange = (checked: boolean) => {
    setInputs((values) => setFormState({ ...values, mfa: checked }));
  };

  const areFormInputsInvalid = (): boolean | undefined =>
    Array.from(inputs.validity.values()).some((value) => value === false);

  const validateFormat = (e: FocusEvent<HTMLInputElement>) => {
    setInputs((values) => updateErrors(values, e));
  };

  return (
    <Modal
      open={open}
      defaultOpen
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          onClose();
        }
      }}
      title={intl.formatMessage({
        defaultMessage: 'Invite New User',
        id: 'mLma0Q',
        description: 'Invite New User title.',
      })}
    >
      <Modal.Content>
        {isError && (
          <div className="mb-4 rounded border border-red-400 bg-red-25 p-4 text-red-400">
            {error.message}
          </div>
        )}

        {isPending && (
          <span className="p-4">
            <LoadingIndicator />
          </span>
        )}

        <form className="w-full max-w-sm" onSubmit={handleSubmit}>
          <MemberGeneralInfo
            roles={roles}
            onPropertyChanged={handleChange}
            onRolesChanged={handleRolesChange}
            onMfaChanged={handleMfaChange}
            defaultMfaValue={inputs.mfa}
            onValueEntered={validateFormat}
            errors={inputs.errors}
          />
        </form>

        {member && <InvitationResult member={member} />}
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button>
            <FormattedMessage
              description="Cancel Button: label for cancel button"
              defaultMessage="Cancel"
              id="f4OIxJ"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Button
          variant="positive"
          onClick={handleSubmit}
          disabled={member !== undefined || areFormInputsInvalid() || status === 'pending'}
        >
          <FormattedMessage
            description="Submit button label for new member form."
            defaultMessage="Invite User"
            id="QqboZt"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default NewInvitation;
